import axios from 'axios';

const loginSymfony = async (props) => axios.post('/login_check', props);
const refreshToken = async (props) => axios.post('/login_check/refresh', props);

const getRolesUser = async (token) => {
    const response = await axios.get('/roles-user-data', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
};


const loggedUser = async (token) => axios.get('/logged-user', token);

export default {
    loginSymfony,
    refreshToken,
    loggedUser,
    getRolesUser
};
