/* eslint-disable */
import newsApi from '../_api/index.js';
import categoriesApi from '../../categoriesServices/_api/index.js';
import store from '../../store'; 

const getNewsAction = async ({ commit, getters, state }, searchQuery) => {
    try {
        // filters
        const categoryId = getters.categorySelected?.id;
        const searchQuery = getters.searchQuery
        // action
        const news = await newsApi.getNews(categoryId, searchQuery);
        commit('setNews',  news)
    } catch (err) {
        console.log('err: ', err)
    }
}

const getLatestNewsAction = async ({commit}) => {
    try {
        const response = await newsApi.getLatestNews();
        commit('setLatestNews',  response.data)
    } catch (err) {
        console.log('err: ', err)
    }
}

const getCategoriesAction = async ({ commit }) => {
    try {
        const categoriesResponse = await categoriesApi.getCategories()
        const categories = categoriesResponse.data
        store.commit('categoryStore/setCategories', categories)
    } catch (err) {
        console.log('Error: ', err);
        throw err;
    }
};

const getNewsByCategoryIdAction = async ({ commit }, categoryId) => {
    try {
        commit('setSelectedCategoryId', categoryId); 
        const response = await newsApi.getNewsByCategoryId(categoryId)
        commit('setNews',  response.data)
    } catch (err) {
        console.log('err: ', err)
    }
}

const getNewsByIdAction = async ({ commit }, { id }) => {
    try {
        const response = await newsApi.getNewsById(id);
        return response
    } catch (err) {
        console.log('err: ', err)
    }
}

const saveNewsAction = async ({ commit }, payload) => {
    try {
        const formData = new FormData();
        formData.append('title', payload.title);
        formData.append('category_id', payload.category_id);
        formData.append('description', payload.description);
        formData.append('publishedAt', payload.publishedAt);
        formData.append('file', payload.file);

        const response = await newsApi.saveNews(formData);
        console.log('Saved news response: ', response);
    } catch (error) {
        console.error('Error saving news: ', error);
    }
}

const updateNewsAction = async ({ commit }, payload) => {
    try {
        const formData = new FormData();
        formData.append('id', payload.id);
        formData.append('title', payload.title);
        formData.append('category_id', payload.category_id);
        formData.append('description', payload.description);
        formData.append('publishedAt', payload.publishedAt);
        formData.append('image', payload.image);
        formData.append('file', payload.file);
        console.log('entrando al ep: ', )
        const response = await newsApi.updateNews(formData);
        console.log('Updated news response: ', response);
    } catch (error) {
        console.error('Error updating news: ', error);
    }
}

const deleteNewsAction = async ({ commit }, payload) => {
    try {
        const response = await newsApi.deleteCategory(payload);
        console.log('response: ', response)
        if (response.data.status === 'ok') {
            commit('removeNews', response.data.deletedNewsId);
        }
    } catch (error) {
        console.log('error: ', error)
    }
};

export default {
    getCategoriesAction,
    getLatestNewsAction,
    saveNewsAction,
    updateNewsAction,
    getNewsByIdAction,
    getNewsAction,
    getNewsByCategoryIdAction,
    deleteNewsAction
}