import axios from 'axios';

// collectionOperations
const postUser = async (params) => axios.post('/user', params);
const getAllDataUser = async () =>  axios.get('/all-user-data');
const getMainDataUser = async () => axios.get('/main-user-data');
const getBirthdayDataUser = async () => axios.get('/birthday-user-data');
const postProfileImage = async (params) => axios.post('/profile-image', params, {headers: {
    'Content-type': "multipart/form-data"
    }});
// itemOPerations
const getAllDataUserById = async (payload) => axios.get(`/all-user-data/${payload}`, payload);
const getMainDataUserById = async (userId) => axios.get(`/main-user-data/${userId}`);
const updateMainDataUser = async (payload) => axios.put(`/main-user-data/${payload.id}`, payload);

// Assets
const SaveUserAsset = async (payload) => axios.post('/main-user-data/asset', payload);
const getAllUserAssets  = async (userId) => axios.get(`/users/${userId}/assets`, userId)
const deleteUserAsset  = async (payload) => axios.post(`/main-user-data/asset/delete`, payload)

const deleteUser = async (userId) => axios.put(`/user/${userId}`);
const updatePassword = async (payload, props) => axios.put(`/change-password/${payload.id}`, props);

export default {
    postUser,
    getAllDataUser,
    getMainDataUser,
    getAllDataUserById,
    getMainDataUserById,
    updateMainDataUser,
    deleteUser,
    updatePassword,
    postProfileImage,
    getBirthdayDataUser,
    SaveUserAsset,
    getAllUserAssets,
    deleteUserAsset
};