import store from '../store/index.js';
import axios from "axios";

store.subscribe((mutation) => {
    switch(mutation.type) {
        case 'LoginStore/setToken':
            if(mutation.payload){
                axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
                localStorage.setItem('token', mutation.payload);
            } else{
                axios.defaults.headers.common['Authorization'] = '';
                localStorage.removeItem('token');
            }
        break;
    }
});