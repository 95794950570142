import DayCloseApi from '../_api/index.js';
import Swal from 'sweetalert2';

const registerDayClose = async (_, { payload, callback }) => {
    const registerDay = DayCloseApi.registerDayClose(payload);
    registerDay
        .then((res) => {
            if(callback) callback(res);
        })
        .catch((error) => {
            const { data } =  error.response;
            //this.$swal(data.title);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: data.detail,
            });
        });
};

const getAllUserDayClose = async ({ commit }, { userId }  ) => {
    const getAll = DayCloseApi.allUserDayClose(userId);
    getAll
        .then((res) => {
            const { data } = res;
            commit('setAllDayClose', {
            factoring: data,
            });
            // callback();
        })
        .catch((error) => {
            const { data } = error;
            console.log('Factoring broker list error ', data);
            // callback();
        });
};

const getUserDayClose = async ({ commit }, { payload}  ) => {
    const getDayClose = DayCloseApi.getDayClose(payload);
    getDayClose
        .then((res) => {
            const { data } = res;
            commit('setDayClose', {
            factoring: data,
            });
            // callback();
        })
        .catch((error) => {
            const { data } = error;
            console.log('Factoring broker get error ', data);
            // callback();
        });
};

const updateDayClose = async (_,{ payload, callback }) => {
    const updateDay = DayCloseApi.updateDayClose(payload);
    updateDay
        .then((res) => {
            if(callback) callback(res);
        })
        .catch((error) => {
            const { data } =  error;
            console.log('Error  111: ', data);
        });
};

const deleteDayClose = async (_,{ payload, callback }) => {
    const deleteDay = DayCloseApi.deleteDayClose(payload);
    deleteDay
        .then((res) => {
            if(callback) callback(res);
        })
        .catch((error) => {
            const { data } =  error;
            console.log('Error saving: ', data);
        });
};

export default{
    registerDayClose,
    getAllUserDayClose,
    getUserDayClose,
    updateDayClose,
    deleteDayClose,
}