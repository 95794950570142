import UserMetaApi from '../_api/index.js';

const postUserMetaAction = async({ payload, callback }) => {
    const postUserMeta = UserMetaApi.postUserMeta(payload);
    postUserMeta
        .then((res) => {
            if (callback) callback(res);
        })
        .catch((error) => {
            const { data } = error.response;
            console.log('Error saving ', data);
        });
};

const putUserMetaAction = async({ payload, callback }) => {
    const putUserMeta = UserMetaApi.putUserMetaById(payload);
    putUserMeta
        .then((res) => {
            if(callback) callback(res);
        })
        .catch((error) => {
            const { data } = error.response;
            console.log('Put user error ', data);
        });
};

const deleteUserMetaAction = async({ payload, callback }) => {
    const deleteUserAction = UserMetaApi.deleteUserMetaById(payload);
    deleteUserAction
    .then((res) => {
        if(callback) callback(res);
    })
    .catch((error) => {
        const { data } = error.response;
        console.log('Put user error ', data);
    });
};

export default {
    postUserMetaAction,
    putUserMetaAction,
    deleteUserMetaAction,
};