<template>
    <div id="main-content" class="blog-page">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <div class="col-12">
                    <div class="card">
                        <div class="body">
                            <h6>New Category</h6>
                            <ValidationObserver v-slot="{ invalid }" ref="form">
                                <form @submit.prevent="saveCategory()">
                                <div class="row clearfix">
                                    <div class="col-12 col-md-6 form-group">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <input type="text" class="form-control" v-model="category.name" placeholder="Item">
                                            <span class="text-danger">{{ getErrorMessage('name', errors) }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-md-6 form-group">
                                            <input type="text" class="form-control" v-model="category.description" placeholder="Description"/>
                                    </div>
                                </div>
                                <button :disabled="invalid" type="submit" class="btn btn-primary mr-1">Create</button>
                                </form>
                            </ValidationObserver>
                        </div>

                        
                        <div class="body">
                            <h6>Categories</h6>
                            <div v-if="categoriesList.length === 0" class="alert alert-warning" role="alert">
                                No categories found.
                            </div>
                            <table v-else class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Count</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(category) in categoriesList" :key="category.id">
                                        <th scope="row">{{ category.id }}</th>
                                        <td>{{ category.name }}</td>
                                        <td>{{ category.description }}</td>
                                        <td>{{ category.count }}</td>
                                        <td class="text-center">
                                            <i class="fa fa-trash-o fa-2x" @click="deleteCategory(category)"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                       
                    </div>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import BreadCrumb from '@/components/BreadCrumb'
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';

export default {
    name: 'categories',
    components: {
        BreadCrumb,
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            category: {
                name: null,
                description: null
            }
        } 
    },
    computed: {
        ...mapGetters('categoryStore', ['categoriesList']),
    },
    methods: {
        ...mapActions('categoryStore', ['getCategoriesAction', 'SaveCategoryAction', 'deleteCategoryAction']),

        getErrorMessage(fieldName, errors) {
          if (errors.length) {
            return `The ${fieldName} is required`;
          }
          return '';
        },

        clearForm() {
          // Limpiar los campos del formulario
          this.category.name = '';
          this.category.description = '';
        },

        async saveCategory () {
            await this.$refs.form.validate();
              // Si el formulario es válido, procedemos a guardar la categoria
              if (!this.$refs.form.errors.length) {
                await this.SaveCategoryAction(this.category)
                // limpiar el form
                this.clearForm()
                // Reiniciar los errores de validación
                this.$refs.form.reset();
              }
        },

        async deleteCategory(category) {
            await this.deleteCategoryAction({category})
        }
    },
    async created () {
        await this.getCategoriesAction()
    }
}
</script>

<style scoped>
/* Add any scoped styles here */
</style>
