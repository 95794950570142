<template>
    <div class="card">
      <div class="body search">
        <div class="input-group m-b-0">
          <input 
            type="text"
            class="form-control"
            placeholder="Search..."
            :value="searchQuery"
            @input="updateSearchQuery($event.target.value)"
            @keyup.enter="searchNews"
          />
          <div class="input-group-append clear-search" v-if="searchQuery" @click="clearSearch">
            <span class="input-group-text"><i class="icon-close"></i></span>
          </div>
          <div class="input-group-prepend search" @click="searchNews">
            <span class="input-group-text"><i class="icon-magnifier"></i></span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
  /* eslint-disable */
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  
  export default {
    name: 'Search',
    computed: {
      ...mapGetters('newsStore', ['searchQuery']),
    },
    methods: {
      ...mapActions('newsStore', ['getNewsAction']),
      ...mapMutations('newsStore', ['clearSearchQuery', 'setSearchQuery']),
  
      async searchNews() {
        await this.getNewsAction();
      },
      updateSearchQuery(value) {
        this.setSearchQuery(value);
      },
      clearSearch() {
        this.clearSearchQuery();
        this.searchNews();
      }
    }
  };
</script>
  
<style scoped>
  /* Estilos opcionales */
  .search {
    cursor: pointer; 
  }
  .clear-search {
    cursor: pointer;
  }
  .icon-close::before {
    content: '\00d7'; /* Unicode for '×' symbol */
  }
</style>
  