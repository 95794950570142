const setNews = (state, payload) => {
    state.news = [ ...payload ];
};

const setLatestNews = (state, payload) => {
    state.latestNews = [ ...payload ];
};

const setSearchQuery = (state, query) => {
    state.searchQuery = query;
};

const setCategorySelected = (state, category) => {
    state.categorySelected = category;
};

const clearSearchQuery = (state) => {
    state.searchQuery = null;
};

const setActiveCategoryId = (state, categoryId) => {
    state.activeCategoryId = categoryId;
};

const removeNews = (state, newsId) => {
    state.news = state.news.filter(news => news.id !== newsId);
};

export default {
    setNews,
    setLatestNews,
    setSearchQuery,
    setCategorySelected,
    clearSearchQuery,
    setActiveCategoryId,
    removeNews
};
