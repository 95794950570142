<template>
    <div id="main-content" class="blog-page">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>

            <div class="row clearfix">
                <div class="col-lg-12 col-md-12">
                    <div class="card">
                        <div class="body project_report">
                            <button type="button" class="btn btn-success mb-3" @click="openModal()"><i class="icon-plus mr-1"></i>Add</button>
                            <div class="table-responsive">
                                <table class="table m-b-0 table-hover">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>#</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                            <th>Options</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(close,index) in dayClose.factoring" :key="index">
                                        <tr>
                                            <td>{{ close.id }}</td>
                                            <td class="project-title">
                                                <span class="badge badge-warning" v-if="(close.status == 0)">draft</span>
                                                <span class="badge badge-success" v-if="(close.status == 1)">commited</span>
                                            </td>
                                            <td>
                                                <span>{{ close.date }}</span>
                                            </td>
                                            <td class="project-actions">
                                                <a href="javascript:void(0);" class="btn btn-outline-warning mr-1" @click="openModal2(close.id)"><i class="icon-eye"></i></a>
                                                <a href="javascript:void(0);" class="btn btn-outline-danger" @click="deleteDayClose(close.id)"><i class="icon-trash"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- modal to add a day close -->
            <div id="addevent" v-if="showModal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h6 class="title" id="defaultModalLabel">New Day Close</h6>
                                </div>
                                <div class="modal-body">
                                    <div class="row clearfix">
                                        <div class="col-lg-4 col-md-4">
                                            <label for="">Author</label>
                                            <div class="form-group">       
                                                <input class="form-control" v-model="getUser.name" disabled>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4">                                         
                                            <div class="form-group">
                                                <label for="">Status</label>      
                                                <select class="form-control show-tick" v-model="newDayCloseData.status">
                                                    <option>...</option>
                                                    <option value=0>Draft</option>
                                                    <option value=1>Commited</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                                <label for="">Date</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="icon-calendar"></i></span>
                                                    </div>
                                                    <datepicker class="form-control show-tick" :bootstrap-styling="false" :disabled-dates="date.disabledDates" v-model="newDayCloseData.date"></datepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-lg-12 col-md-12 mb-2">
                                            <label>Today's work <a href="javascript:void(0);"><i class="icon-plus ml-1" @click="addWork"></i></a></label>
                                        </div>
                                    </div>
                                    <div class="row clearfix mt-2" v-if="addW">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label class="mr-2">Worked</label>
                                                <a href="javascript:void(0);" @click="addForm(0)"><i class="icon-plus mr-1"></i>add</a><hr>  
                                                <div class="table-responsive">
                                                    <table class="table m-b-2 table-hover">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>DESCRIPTION</th>
                                                                <th>CRITIC</th>
                                                                <th>TASK</th>
                                                                <th>TIME</th>
                                                                <th>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="row in rowsW" :key="row.id">
                                                            <tr>
                                                                <td><textarea cols="22" rows="1" v-model="row.description"></textarea></td>
                                                                <td><input type="checkbox" v-model="row.type" true-value="critic" false-value="no critic"></td>
                                                                <td><input type="text" v-model="row.task"></td>
                                                                <td><input type="text" v-model="row.time"></td>
                                                                <input type="text" v-model="row.status" hidden>
                                                                <td><a href="javascript:void(0);" @click="removeForm(row, 0)"><i class="icon-trash mr-1"></i>delete</a></td>
                                                            </tr>
                                                        </tbody>            
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label class="mr-2">Finished</label>
                                                <a href="javascript:void(0);" @click="addForm(1)"><i class="icon-plus mr-1"></i>add</a><hr> 
                                                <div class="table-responsive">
                                                    <table class="table m-b-2 table-hover">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>DESCRIPTION</th>
                                                                <th>CRITIC</th>
                                                                <th>TASK</th>
                                                                <th>TIME</th>
                                                                <th>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="row in rowsF" :key="row.id">
                                                            <tr>
                                                                <td><textarea cols="22" rows="1" v-model="row.description"></textarea></td>
                                                                <td><input type="checkbox" v-model="row.type" true-value="critic" false-value="no critic"></td>
                                                                <td><input type="text" v-model="row.task"></td>
                                                                <td><input type="text" v-model="row.time"></td>
                                                                <input type="text" v-model="row.status" hidden value="finished">
                                                                <td><a href="javascript:void(0);" @click="removeForm(row, 1)"><i class="icon-trash mr-1"></i>delete</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label class="mr-2">Blockages</label>
                                                <a href="javascript:void(0);" @click="addForm(2)"><i class="icon-plus mr-1"></i>add</a><hr> 
                                                <div class="table-responsive">
                                                    <table class="table m-b-2 table-hover">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>DESCRIPTION</th>
                                                                <th>CRITIC</th>
                                                                <th>TASK</th>
                                                                <th>TIME</th>
                                                                <th>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="row in rowsB" :key="row.id">
                                                            <tr>
                                                                <td><textarea cols="22" rows="1" v-model="row.description"></textarea></td>
                                                                <td><input type="checkbox" v-model="row.type" true-value="critic" false-value="no critic"></td>
                                                                <td><input type="text" v-model="row.task"></td>
                                                                <td><input type="text" v-model="row.time"></td>
                                                                <input type="text" v-model="row.status" hidden value="blockages">
                                                                <td><a href="javascript:void(0);" @click="removeForm(row, 2)"><i class="icon-trash mr-1"></i>delete</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label class="mr-2">Working On</label>
                                                <a href="javascript:void(0);" @click="addForm(3)"><i class="icon-plus mr-1"></i>add</a><hr> 
                                                <div class="table-responsive">
                                                    <table class="table m-b-2 table-hover">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>DESCRIPTION</th>
                                                                <th>CRITIC</th>
                                                                <th>TASK</th>
                                                                <th>TIME</th>
                                                                <th>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="row in rowsWO" :key="row.id">
                                                            <tr>
                                                                <td><textarea cols="22" rows="1" v-model="row.description"></textarea></td>
                                                                <td><input type="checkbox" v-model="row.type" true-value="critic" false-value="no critic"></td>
                                                                <td><input type="text" v-model="row.task"></td>
                                                                <td><input type="text" v-model="row.time"></td>
                                                                <input type="text" v-model="row.status" hidden>
                                                                <td><a href="javascript:void(0);" @click="removeForm(row, 3)"><i class="icon-trash mr-1"></i>delete</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" @click="registerDayClose(rowsW, rowsF, rowsB, rowsWO)">Add</button>
                                    <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- modal information a day close -->
            <div id="addevent" v-if="showModal2">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h6 class="title" id="defaultModalLabel">Day Close Information</h6>
                                </div>
                                <div class="modal-body">
                                    <div class="row clearfix">
                                        <div class="col-lg-4 col-md-4">
                                            <label for="">Author</label>
                                            <div class="form-group ">       
                                                <input class="form-control input input-sm" v-model="getUser.name" disabled>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group" v-if="getDay.factoring[0]">
                                                <label for="">Status</label>       
                                                <select class="form-control show-tick" v-model="getDay.factoring[0].dayClose.status" disabled>
                                                    <option>...</option>
                                                    <option value=0>Draft</option>
                                                    <option value=1>Commited</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group" v-if="getDay.factoring[0]">
                                                <label for="">Date</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="icon-calendar"></i></span>
                                                    </div>
                                                    <datepicker disabled class="form-control" :bootstrap-styling="false" :disabled-dates="date.disabledDates" v-model="getDay.factoring[0].dayClose.date"></datepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-lg-12 col-md-12 mb-2">
                                            <label>Information Work <a href="javascript:void(0);"><i class="icon-plus ml-1" @click="addWork"></i></a></label>
                                        </div>
                                    </div>
                                    <div class="row clearfix mt-2" v-if="addW">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label class="mr-2">Worked</label>
                                                <div class="table-responsive">
                                                    <table class="table m-b-2 table-hover">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>DESCRIPTION</th>
                                                                <th>CRITIC</th>
                                                                <th>TASK</th>
                                                                <th>TIME</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="row in rowsW" :key="row.id">
                                                            <tr>
                                                                <td><textarea cols="22" rows="1" v-model="row.description" disabled></textarea></td>
                                                                <td><input type="checkbox" v-model="row.type" true-value="critic" false-value="no critic" disabled></td>
                                                                <td><input type="text" v-model="row.task" disabled></td>
                                                                <td><input type="text" v-model="row.time" disabled></td>
                                                            </tr>
                                                        </tbody>
                                                        
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label class="mr-2">Finished</label>
                                                <div class="table-responsive">
                                                    <table class="table m-b-2 table-hover">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>DESCRIPTION</th>
                                                                <th>CRITIC</th>
                                                                <th>TASK</th>
                                                                <th>TIME</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="row in rowsF" :key="row.id">
                                                            <tr>
                                                                <td><textarea cols="22" rows="1" v-model="row.description" disabled></textarea></td>
                                                                <td><input type="checkbox" v-model="row.type" true-value="critic" false-value="no critic" disabled></td>
                                                                <td><input type="text" v-model="row.task" disabled></td>
                                                                <td><input type="text" v-model="row.time" disabled></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label class="mr-2">Blockages</label>
                                                <div class="table-responsive">
                                                    <table class="table m-b-2 table-hover">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>DESCRIPTION</th>
                                                                <th>CRITIC</th>
                                                                <th>TASK</th>
                                                                <th>TIME</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="row in rowsB" :key="row.id">
                                                            <tr>
                                                                <td><textarea cols="22" rows="1" v-model="row.description" disabled></textarea></td>
                                                                <td><input type="checkbox" v-model="row.type" true-value="critic" false-value="no critic" disabled></td>
                                                                <td><input type="text" v-model="row.task" disabled></td>
                                                                <td><input type="text" v-model="row.time" disabled></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label class="mr-2">Working On</label>
                                                <div class="table-responsive">
                                                    <table class="table m-b-2 table-hover">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>DESCRIPTION</th>
                                                                <th>CRITIC</th>
                                                                <th>TASK</th>
                                                                <th>TIME</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="row in rowsWO" :key="row.id">
                                                            <tr>
                                                                <td><textarea cols="22" rows="1" v-model="row.description" disabled></textarea></td>
                                                                <td><input type="checkbox" v-model="row.type" true-value="critic" false-value="no critic" disabled></td>
                                                                <td><input type="text" v-model="row.task" disabled></td>
                                                                <td><input type="text" v-model="row.time" disabled></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" @click="updateDayClose">Update</button>
                                    <button type="button" class="btn btn-secondary" @click="closeModal2">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import BreadCrumb from '@/components/BreadCrumb'
export default {
    name:'DayCloseComponent',
    components: {
        BreadCrumb,
        Datepicker,
    },data (){
        return{
            showModal: false,
            showModal2: false,
            date: '',
            addW: false,
            rowsW: [],
            rowsF: [],
            rowsB: [],
            rowsWO: [],
            newDayCloseData: {
                user_id: '',
                status: '',
                date: '',
                items: [],
            },
            editDayCloseData: {
                status: '',
                date: '',
                items: [],
            }
        }
    },
    computed:{
        getUser(){
            const user = this.$store.getters['LoginStore/user'];
            return user ? user : '';
        },
        dayClose(){
            const dayClose = this.$store.getters['dayCloseStore/getAllUserDayClose'];
            return dayClose ? dayClose : '';
        },
        getDay(){
            const dayClose = this.$store.getters['dayCloseStore/getDayClose'];
            return dayClose ? dayClose : '';
        }
    },
    watch:{
        getDay(){
            for(let item of this.getDay.factoring){
                if(item.status == 'worked')
                    this.rowsW.push(item);
                if(item.status == 'finished')
                    this.rowsF.push(item);
                if(item.status == 'blockages')
                    this.rowsB.push(item);
                if(item.status == 'working')
                    this.rowsWO.push(item);
            }
        },
    },  
    mounted(){
        this.getAllDayClose()
        this.dates()
    },
    methods: {
        openModal: function () {
          this.showModal = true;
        },
        closeModal: function () {
            this.showModal = false;
            this.addW = false;
            this.rowsW = [];
            this.rowsF = [];
            this.rowsB = [];
            this.rowsWO = [];
            this.newDayCloseData = {};
        },
        openModal2(payload){
            this.showModal2 = true;
            this.$store.dispatch('dayCloseStore/getUserDayClose', {payload});
        },
        closeModal2(){
            this.showModal2 = false;
            this.addW = false;
            this.rowsW = [];
            this.rowsF = [];
            this.rowsB = [];
            this.rowsWO = [];
        },
        getAllDayClose(){
            const userId = this.getUser.id;
            this.$store.dispatch('dayCloseStore/getAllUserDayClose', {userId});
        },
        registerDayClose(row1, row2, row3, row4){
            this.newDayCloseData.user_id = this.getUser.id;
            this.newDayCloseData.status = parseInt(this.newDayCloseData.status);
            this.newDayCloseData.items = row1.concat(row2).concat(row3).concat(row4).flat();
            const data = this.newDayCloseData;
            this.$store.dispatch('dayCloseStore/registerDayClose', {
                payload: data,
                callback: () => {this.getAllDayClose()
                    this.$swal.fire({
                icon: "success",
                title: "Success",
                text: "El usuario ha sido registrado con exito",
            });}
            });
            this.showModal = false;
            this.rowsW = [];
            this.rowsF = [];
            this.rowsB = [];
            this.rowsWO = [];
        },
        updateDayClose(){
            this.editDayCloseData.items = 
                this.rowsW.concat(this.rowsF).concat(this.rowsWO).concat(this.rowsB).flat();
            this.editDayCloseData.status = this.getDay.factoring[0].dayClose.status;
            this.editDayCloseData.date = this.getDay.factoring[0].dayClose.date;
            let data = this.editDayCloseData;
            this.$store.dispatch('dayCloseStore/updateDayClose', {
                payload: data,
                callback: () => {this.getAllDayClose()}
            });
        },
        deleteDayClose(payload){
            this.$store.dispatch('dayCloseStore/deleteDayClose', {
                payload, 
                callback: () => {this.getAllDayClose()}
            });
        },
        dates(){
            this.date = {
                disabledDates:{
                    from: new Date()
                }
            }
        },
        addWork(){
            this.addW == true
                ? this.addW = false
                : this.addW = true;
        },
        addForm(num){
            let row;
            let status;
            switch(num) {
                case 0: row = this.rowsW; status = 'worked'; break;
                case 1: row = this.rowsF; status = 'finished'; break;
                case 2: row = this.rowsB; status = 'blockages'; break;
                case 3: row = this.rowsWO; status = 'working'; break;
            }
            const newRow = {
                id: row.length + 1,
                description: '',
                task: '',
                time: '',
                type: 'no critic',
                status: status
            };
            row.push(newRow);
        },
        removeForm(row, num){
            let rows;
            switch(num) {
                case 0: rows = this.rowsW; break;
                case 1: rows = this.rowsF; break;
                case 2: rows = this.rowsB; break;
                case 3: rows = this.rowsWO; break;
            }
            rows.splice(rows.indexOf(row), 1);
        }
    }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: top;
}

.modal-dialog {
    width: 1200px; 
    height: 600px; 
    max-width: 1200px; 
    max-height: 600px; 
    overflow-y: scroll;
}
</style>