const setToken = (state, token) => state.token = token;

const setUser = (state, data) => state.user = data;

const setAuth = (state, isAuthenticated) => state.auth = isAuthenticated;

const setSessionExpired = (state, expired) => state.sessionExpired = expired;

const firstSession = (state, fSession) => state.firstSession = fSession; 

export default {
    setToken,
    setUser,
    setAuth,
    setSessionExpired,
    firstSession,
};