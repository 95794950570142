import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    news: [],
    latestNews: [],
    categorySelected: null,
    searchQuery: null,
    activeCategoryId: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
