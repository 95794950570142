import Vue from 'vue';
import Vuex from 'vuex';

//imports services
import userStore from '../userServices/_store/index.js';
import newsStore from '../newsServices/_store/index.js';
import categoryStore from '../categoriesServices/_store/index.js';
import userMetaStore from '../userMetaServices/_store/index.js';
import LoginStore from '../auth/login/_store/index.js';
import dayCloseStore from '../dayCloseServices/_store/index.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules:{
        userStore,
        userMetaStore,
        LoginStore,
        dayCloseStore,
        newsStore,
        categoryStore
    },
});

export default store;