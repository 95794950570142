<template>
    <div id="main-content">
        <div class="container-fluid">
            <div class="block-header">
                 <bread-crumb></bread-crumb>
            </div>
            <!-- <div class="row clearfix">
                <div  v-for="basicLine in analiticalLineChartDetail" :key="basicLine.title" class="col-lg-3 col-md-6 col-sm-6">
                    <analitical-data  
                        :title = "basicLine.title"
                        :value = "basicLine.value"
                        :details = "basicLine.details"
                        :class_nm = "basicLine.class_nm"
                        :options= basicLine.options
                    ></analitical-data>
                </div>
            </div> -->
            <div class="row clearfix">
                <!-- <div class="col-lg-6 col-md-12">
                    <top-products  :options="products"></top-products>
                </div> -->
                <!-- <div class="col-lg-3 col-md-6">
                    <referrals></referrals>
                </div> -->
                <!-- <div class="col-lg-3 col-md-6">
                    <total-revenue :doughnutoptions="revenueDoughnut" :baroptions="revenueBar"></total-revenue>
                </div> -->
            </div>
            <div class="row clearfix">
                <!-- <div class="col-lg-4 col-md-12">
                    <analitical-chat></analitical-chat>
                </div> -->
                <!-- <div class="col-lg-8 col-md-12">
                    <analitical-managed :options="dataManagedBar"></analitical-managed>
                </div>                 -->
            </div>
            <div class="row clearfix">
                <!-- <div class="col-lg-4 col-md-12">
                    <lucid-feed></lucid-feed>
                </div> -->
                <div class="col-lg-4 col-md-12">
                    <birthday-feed></birthday-feed>
                </div>
                <!-- <div class="col-lg-4 col-md-12">
                   <member-info></member-info>
                </div> -->
            </div>
            <div class="row clearfix">
                <div class="col-lg-12">
                    <latest-news></latest-news>  
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import BreadCrumb from '@/components/BreadCrumb'
// import AnalyticalData from '@/components/core/AnalyticalData.vue'
// import AnalyticalTopproducts from '@/components/core/AnalyticalTopproducts.vue'
// import AnaliticalReferrals from '@/components/core/AnaliticalReferrals.vue'
// import AnaliticalRevenue from '@/components/core/AnaliticalRevenue.vue'
// import AnaliticalChat from '@/components/core/AnaliticalChat.vue'
// import AnaliticalManaged from '@/components/core/AnaliticalManaged.vue'
// import LucidFeeds from '@/components/core/LucidFeeds.vue'
import BirthdayFeed from '@/components/admin/widgets/BirthdayFeed.vue'
// import TeamLeader from '@/components/core/TeamLeader.vue'
import LatestNews from '@/components/core/LatestNews.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'IndexComponent',
    components: {
        BreadCrumb,
        // 'lucid-feed':LucidFeeds,
        'birthday-feed':BirthdayFeed,
        // 'member-info':TeamLeader,
        'latest-news':LatestNews
    },methods:{
        ...mapActions('newsStore', ['getLatestNewsAction']),
    },
    data (){
        return{
        }
    },
    async created(){
        console.log('index: ', )
        await this.getLatestNewsAction()
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    mounted:function(){
        const session = this.$store.getters['LoginStore/firstSession'];
        if(!session) {
            this.$toast.open({
                message: "Hello, welcome to Bitecla.",
                type: "info",
                duration: 3800,
                position: 'top-right',
                dismissible: true,
                queue: false,
            })
            this.$store.dispatch('LoginStore/firstSessionCheck');
        }       
    },
}
</script>
<style scoped>

</style>