<template>
    <div>
        <div class="body">
            <div class="img-post">
                <img class="d-block img-fluid" :src="imageSource" alt="First slide">
            </div>
            <!-- Utilizando router-link para navegar a la ruta con props -->
            <h3><router-link :to="getNewsDetailsRoute(title)">{{ title }}</router-link></h3>
            <div v-html="convertedDescription"></div>
        </div>
        <div class="footer">
            <div class="actions">
                <button @click="navigateToNewsDetails" class="btn btn-outline-secondary">
                    {{ btn_contain }}
                </button>

                <button @click="navigateToNewsEdit" class="btn btn-outline-primary ml-2">
                    Update
                </button>

                <button @click="deleteNews" class="btn btn-outline-danger ml-2">
                    Delete
                </button>
            </div>
            <ul class="stats">
                <li v-for="status in sub_details" :key="status.sub_title"><a :href="status.url">{{ status.sub_title }}</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'BlogLists',
    props: {
        title: {
            type: String,
            required: true
        },
        image: {
            type: Object,
            default: () => ({})
        },
        description: {
            type: String,
            required: true
        },
        btn_contain: {
            type: String,
            required: true
        },
        sub_details: {
            type: Array,
            required: true
        },
        news: {
            type: Object,
            required: true
        }
    },
    computed: {
        imageSource() {
            if (this.image && this.image.path) {
                return this.image.path;
            } 
            return 'http://localhost:3000/img/blog-page-2.5fa2b962.jpg';
        },
        convertedDescription() {
            const maxLength = 300;
            if (this.description.length > maxLength) {
                return `${this.description.substring(0, maxLength)}...`;
            }
            return this.description;
        }
    },
    methods: {
        ...mapActions('newsStore', ['deleteNewsAction']),

        removeAccents(str) {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        },
        generateSlug(title) {
            const cleanTitle = this.removeAccents(title.toString().toLowerCase().trim());
            return cleanTitle
                .replace(/\s+/g, '-')        // Reemplazar espacios con guiones
                .replace(/[^\w-]+/g, '')     // Remover caracteres no alfanuméricos excepto guiones
                .replace(/--+/g, '-');       // Reemplazar múltiples guiones con uno solo
        },
        // Función para generar la ruta (/news-details/:slug) / detalles de la noticia
        getNewsDetailsRoute(title) {
            const slug = this.generateSlug(title);
            return {
                name: 'NewsDetails',
                params: { slug: slug, id: this.news.id },
                //query: { id: this.news.id }  // Pasar el id como query parameter
            };
        },
        navigateToNewsDetails() {
            const route = this.getNewsDetailsRoute(this.title);
            this.$router.push(route);
        },
        navigateToNewsEdit () {
            const slug = this.generateSlug(this.title);
            this.$router.push({ name: 'NewsEdit',  params: { slug: slug, id: this.news.id } });
        },
        async deleteNews() {
            await this.deleteNewsAction({news: this.news})
        }
    },
    created () {
        console.log('created: ', this.news)
    }
};
</script>

<style scoped>
/* Estilos opcionales */
</style>
