import actions from './actions';
import mutations from './mutations.js';
import getters from './getters.js';

const state = {
    loginError: false,
    token: null,
    user: null,
    auth: !!localStorage.getItem('token'),
    roles: localStorage.getItem('roles'),
    sessionExpired: false,
    firstSession: false,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
