const setUserState = (state, payload) => {
    state.userList = payload;
};

const setUserData = (state, payload) => {
    state.user = payload;
};

const setUserBirthday = (state, payload) => {
    state.usersBirthday = payload;
};

const updateUserAssets = (state, asset) => {
    if (!state.assets) {
        state.assets = []
    }
    state.assets.push(asset)
};

const setUserAssets = (state, payload) => {
    state.assets = [ ...payload ];
};

const removeUserAsset = (state, assetId) => {
    state.assets = state.assets.filter(asset => asset.id !== assetId);
};

export default {
    setUserState,
    setUserData,
    setUserBirthday,
    updateUserAssets,
    setUserAssets,
    removeUserAsset
};
