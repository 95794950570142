<template>
    <div class="vertical-align-wrap">
        <div class="vertical-align-middle auth-main">
            <div class="auth-box">
                <div class="top">
                    <img src="../../../assets/Bitecla-logo2.svg" alt="BITECLA">
                </div>
                <div class="card">
                    <div class="header">
                        <p class="lead">Login to your account</p>
                    </div>
                    <div class="body">
                        <form class="form-auth-small" v-on:submit.prevent="submit">
                            <div class="form-group">
                                <label for="signin-email" class="control-label sr-only">Username</label>
                                <input type="text" v-model="username" class="form-control" id="signin-email" value="user@domain.com" placeholder="Email">
                            </div>
                            <div class="form-group">
                                <label for="signin-password" class="control-label sr-only">Password</label>
                                <input type="password" v-model="password" class="form-control" id="signin-password" value="thisisthepassword" placeholder="Password">
                            </div>
                            <div class="form-group clearfix">
                                <label class="fancy-checkbox element-left">
                                    <input type="checkbox">
                                    <span>Remember me</span>
                                </label>								
                            </div>
                            <button type="submit" class="btn btn-primary btn-lg btn-block">LOGIN</button>
<!--                            <div class="bottom">-->
<!--                                <span class="helper-text m-b-10"><i class="fa fa-lock"></i> <router-link to="/authentication/page-forgot-password">Forgot password?</router-link></span>-->
<!--                                <span>Don't have an account? <router-link to="/authentication/page-register">Register</router-link></span>-->
<!--                            </div>-->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<!-- END WRAPPER -->
</template>
<script>
export default {
    name:'LoginComponent',
    data() {
      return {
        password: '',
        username: '',
      };
    },

    created() {
      const expired = this.$store.getters['LoginStore/isSessionExpired'];
      if (expired){
        this.$toast.open({
          message: "Session Expired",
          type: "error",
          duration: 5000,
          position: 'top-right',
          dismissible: true
        })
      }
    },
    methods: {
        submit(e){
          const { username, password } = this;
          console.log(username);
          console.log(password);

          this.$store.dispatch('LoginStore/loginAction', {
            props: {
              username,
              password
            },
            callback: () => {
              this.$router.push('/dashboard/index');
              e.preventDefault()
            }
          });
        }
    }
}
</script>
<style scoped>


</style>