/* eslint-disable */
import LoginApi from '../_api/index.js';
import UserApi from "@/services/userServices/_api";

const loginAction = async ({ commit, dispatch }, { props, callback }) => {
    try {

        //LOGIN USER
        const login = await LoginApi.loginSymfony(props);
        const { data } = login;

        //GET ROLES USER LOGGED
        const response = await LoginApi.getRolesUser(data.token);
        const  { roles } = response
        localStorage.setItem("roles", JSON.stringify(roles))

        //envia los datos en ese caso el rol



        commit('setAuth', true);
        dispatch('attempt', data.token);
        callback(data);

        console.log("data:", data);

    } catch (error) {
        if (error.response && error.response.data) {
            commit('setToken', {
                loginError: true,
            });
            setTimeout(() => {
                commit('setToken', {
                    loginError: false,
                });
            }, 2500);
        }
        console.log(error);
    }
};


const getMainDataUserByIdAction = async ({ commit }, { userId }  ) => {
    const getMainDataById = UserApi.getMainDataUserById(userId);
    getMainDataById
        .then((res) => {
            const { data } = res;
            commit('setUser', data);
        })
        .catch((error) => {
            const { data } = error.response;
            console.log('getMainDataUserByIdAction ERROR:', data);
        });
};

const attempt = async ({ commit, state }, token) => {
    if (token){
        commit('setToken', token);
    }

    if(!state.token){
        return;
    }

    try {
        let response = await LoginApi.loggedUser(token);
        commit('setUser', response.data);
    } catch(e) {
        console.log(e);
        commit('setAuth', false);
        commit('setToken', null);
        commit('setUser', null);
    }
};

const refreshToken = async (tokenRefresh) => {
    const props = {
        refresh_token: tokenRefresh,
    };

    const refresh = LoginApi.refreshToken(props);
    refresh.then((res) => {
        const { data } = res;
        console.log(data);
    }).catch(async (error) => {
        console.log(error);
    });
};

const logoutAction = async ({ commit }) => {
    commit('setAuth', false);
    commit('setToken', null);
    commit('setUser', null);
    commit('firstSession', false);
};

const firstSessionCheck = async ({ commit }) => {
    commit('firstSession', true);
};

export default {
    loginAction,
    refreshToken,
    logoutAction,
    attempt,
    getMainDataUserByIdAction,
    firstSessionCheck,
};
