import { render, staticRenderFns } from "./NewsComponent.vue?vue&type=template&id=05fe4b7e&scoped=true&"
import script from "./NewsComponent.vue?vue&type=script&lang=js&"
export * from "./NewsComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05fe4b7e",
  null
  
)

export default component.exports