import axios from 'axios';

//CollectionsOperations
const getCategories = async() => await axios.get('/categories');

const saveCategory = async (payload) => axios.post('/categories', payload);

const deleteCategory  = async (payload) => axios.post(`/category/delete`, payload)

export default{
    getCategories,
    saveCategory,
    deleteCategory
}