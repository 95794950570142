<template>
    <div id="main-content" class="blog-page">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb-custom :user-name="userData.firstName" :user-lastname="userData.lastname" :is-profile-user="true"></bread-crumb-custom>
            </div>
           <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="body">
                            <ul class="nav nav-tabs">                                
                                <li class="nav-item"><a class="nav-link" v-on:click="setActive('Settings')"  :class="{ active:isActive('Settings') }">Settings</a></li>
                                <li class="nav-item"><a class="nav-link" v-on:click="setActive('assets_management')"  :class="{ active:isActive('assets_management') }">Assets Management</a></li>
                                <li class="nav-item"><a class="nav-link" v-on:click="setActive('preferences')"  :class="{ active:isActive('preferences') }">Preferences</a></li>
                            </ul>
                        </div>
                        <div class="tab-content">
    
                            <div v-if="userData" class="tab-pane" id="Settings" :class="{ active:isActive('Settings') }">

                                <div class="body">
                                    <h6>Profile Photo</h6>                                                
                                    <div class="media">
                                        <div v-if="seeImage != null" class="media-left m-r-15">
                                            <img :src="seeImage" height="140px" width="140px" class="user-photo media-object" alt="User">
                                        </div>
                                        <div v-if="seeImage == null" class="media-left m-r-15">
                                          <img :src="userData.profileImage? routeProfile : require('@/assets/userDefaultImg.jpg')" height="140px" width="140px" class="user-photo media-object" alt="User">
                                        </div>                                        
                                        <div class="media-body">
                                            <p>Upload your photo.
                                                <br> <em>Image should be at least 140px x 140px</em></p>
<!--                                            <button type="button" class="btn btn-default-dark" id="btn-upload-photo" >Upload Photo</button>-->
                                            <input type="file" ref="fileInput" class="form-control-file" @change="uploadImage($event)" formenctype="multipart/form-data" >
                                        </div>
                                    </div>
                                </div>

                                <div class="body" style="padding-bottom: 10px !important;">
                                    <h6>Basic Information</h6>
                                    <div class="row clearfix">
                                        <div class="col-lg-6 col-md-12">
                                          <div class="form-group">
                                            <input type="text" class="form-control" v-model="userData.emailCorp" disabled placeholder="Corporate Email">
                                          </div>
                                            <div class="form-group">                                                
                                                <input type="text" class="form-control" placeholder="First Name" v-model="userData.firstName" disabled>
                                            </div>
                                            <div class="form-group">                                                
                                                <input type="text" class="form-control" placeholder="Last Name" v-model="userData.lastname" disabled>
                                            </div>
                                          <div class="form-group">
                                            <input type="text" class="form-control" v-model="userData.phoneNumber" placeholder="Phone">
                                          </div>
                                          <div class="form-group">
                                            <div class="input-group">
                                              <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="icon-present"></i></span>
                                              </div>
                                              <datepicker class="form-control" :bootstrap-styling="true" v-model="userData.birthdate" ></datepicker>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">                                                
                                                <input type="text" class="form-control" v-model="userData.username" placeholder="User Name">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" v-model="userData.middleName" disabled placeholder="Middle Name">
                                            </div>
                                            <div class="form-group">
                                              <input type="text" class="form-control" v-model="userData.secondSurname" disabled placeholder="Second Surname">
                                            </div>
                                            <div class="form-group">
                                              <input type="text" class="form-control" v-model="userData.email" placeholder="Email">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                              <div class="body" style="padding-top: 0 !important; padding-bottom: 10px !important;" >
                                <label>Identification</label>
                                <div class="row clearfix">
                                  <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                      <input type="text" class="form-control" v-model="userData.identificationNumber" placeholder="Identification Number">
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-md-12">
                                    <select v-model="userData.identificationType" class="form-control">
                                      <option>--Select Type--</option>
                                      <option value="PASSPORT"> Passport </option>
                                      <option value="DNI"> DNI </option>
                                      <option value="CEDULA"> Cedula </option>
                                      <option value="EMPLOYEE_NUMBER">Employee Number</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div class="body" style="padding-top: 0 !important;" >
                                <label>Admission Date</label>
                                <div class="row clearfix">
                                  <div class="col-lg-6 col-md-12">
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="icon-calendar"></i></span>
                                      </div>
                                      <datepicker class="form-control" :bootstrap-styling="true" v-model="userData.admissionDate" ></datepicker>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="body" style="padding-top: 0 !important;" >
                                <div class="row clearfix">
                                  <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                      <button type="button" class="btn btn-primary mr-1" @click="updateUser('settings')">Update</button> &nbsp;&nbsp;
                                      <button class="btn btn-default">Cancel</button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="body">
                                    <h6>General Information</h6>
                                    <div class="row clearfix">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Emergency contact">
                                            </div>
                                            <div class="form-group">
                                              <input type="text" class="form-control" placeholder="knowed pathologies">
                                            </div>
                                          <button type="button" class="btn btn-primary mr-1">Update</button> &nbsp;&nbsp;
                                          <button class="btn btn-default">Cancel</button>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                          <div class="form-group">
                                            <select id="genderSelect" class="form-control" v-model="userData.gender">
                                              <option value="m">Male</option>
                                              <option value="f">Female</option>
                                            </select>
                                          </div>
                                          <div class="form-group">
                                            <textarea class="form-control" placeholder="Address"></textarea>
                                          </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="body">
                                  <div class="row clearfix">
                                    <div class="col-lg-6 col-md-12">
                                      <h6>Change Password</h6>
                                      <div class="form-group">
                                        <input type="password" class="form-control" v-model="changePassword.password" placeholder="Current Password">
                                      </div>
                                      <div class="form-group">
                                        <input type="password" class="form-control" v-model="changePassword.newPassword" placeholder="New Password">
                                      </div>
                                      <div class="form-group">
                                        <input type="password" class="form-control" v-model="changePassword.confirmNewPassword" placeholder="Confirm New Password">
                                      </div>
                                    </div>
                                  </div>
                                  <button type="button" class="btn btn-primary mr-1" @click="UpdatePassword">Change Password</button> &nbsp;&nbsp;
                                  <button class="btn btn-default">Cancel</button>
                                </div>

                            </div>
    
                            <div class="tab-pane" id="assets_management" :class="{ active:isActive('assets_management') }">
                              <div v-if="isAdmin" class="body">
                                <h6>New Asset</h6>
                                <ValidationObserver v-slot="{ invalid }" ref="form">
                                  <form @submit.prevent="updateUser('asset')">
                                    <div class="row clearfix">
                                      <div class="col-12 col-md-6 form-group">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                          <input type="text" class="form-control" v-model="asset.item" placeholder="Item">
                                          <span class="text-danger">{{ getErrorMessage('item', errors) }}</span>
                                        </ValidationProvider>
                                      </div>
                                      <div class="col-12 col-md-6 form-group">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                          <input type="text" class="form-control" v-model="asset.description" placeholder="Description"/>
                                          <span class="text-danger">{{ getErrorMessage('description', errors) }}</span>
                                        </ValidationProvider>
                                      </div>
                                      <div class="col-12 col-md-6 form-group">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                          <select class="form-control" v-model="asset.status">
                                            <option value="new">New</option>
                                            <option value="used">Used</option>
                                          </select>
                                          <span class="text-danger">{{ getErrorMessage('status', errors) }}</span>
                                        </ValidationProvider>
                                      </div>
                                      <div class="col-12 col-md-6 form-group">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                          <select class="form-control" v-model="asset.type">
                                            <option value="gift">Gift</option>
                                            <option value="borrowed">Borrowed</option>
                                          </select>
                                          <span class="text-danger">{{ getErrorMessage('type', errors) }}</span>
                                        </ValidationProvider>
                                      </div>
                                      <div class="col-12 col-md-6 form-group">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                          <input type="datetime-local" class="form-control" v-model="asset.date">
                                          <span class="text-danger">{{ getErrorMessage('date', errors) }}</span>
                                        </ValidationProvider>
                                      </div>
                                      <div class="col-12 col-md-6 form-group">
                                          <textarea class="form-control" v-model="asset.notes" placeholder="Notes"></textarea>
                                      </div>
                                    </div>
                                    <button :disabled="invalid" type="submit" class="btn btn-primary mr-1">Create</button>
                                  </form>
                                </ValidationObserver>
                              </div>

                              <div class="body">
                                <h6>Assets</h6>
                                <div v-if="assetsList.length === 0" class="alert alert-warning" role="alert">
                                  You do not have assets assigned
                                </div>
                                <table v-else class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col">ID</th>
                                      <th scope="col">Item</th>
                                      <th scope="col">Description</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Type</th>
                                      <th scope="col">Date</th>
                                      <th scope="col">Note</th>
                                      <th v-if="isAdmin" scope="col"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(asset, index) in assetsList" :key="index">
                                      <th scope="row">{{ asset.id }}</th>
                                      <td>{{ asset.item }}</td>
                                      <td>{{ asset.description }}</td>
                                      <td>{{ asset.status }}</td>
                                      <td>{{ asset.type }}</td>
                                      <td>{{ asset.date }}</td>
                                      <td>{{ asset.notes }}</td>
                                      <td v-if="isAdmin" class="text-center">
                                        <i class="fa fa-trash-o fa-2x" @click="deleteAsset(asset)"></i>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
    
                            <div class="tab-pane" id="preferences" :class="{ active:isActive('preferences') }">
                                <div class="row clearfix">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="body">
                                            <h6>Your Login Sessions</h6>
                                            <ul class="list-unstyled list-login-session">
                                                <li>
                                                    <div class="login-session">
                                                        <i class="fa fa-laptop device-icon"></i>
                                                        <div class="login-info">
                                                            <h3 class="login-title">Mac - New York, United States</h3>
                                                            <span class="login-detail">Chrome - <span class="text-success">Active Now</span></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="login-session">
                                                        <i class="fa fa-desktop device-icon"></i>
                                                        <div class="login-info">
                                                            <h3 class="login-title">Windows 10 - New York, United States</h3>
                                                            <span class="login-detail">Firefox - about an hour ago</span>
                                                        </div>
                                                        <button type="button" class="btn btn-link btn-logout" data-container="body" data-toggle="tooltip" title="Close this login session"><i class="fa fa-times-circle text-danger"></i></button>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="login-session">
                                                        <i class="fa fa-mobile fa-fw device-icon"></i>
                                                        <div class="login-info">
                                                            <h3 class="login-title">Android - New York, United States</h3>
                                                            <span class="login-detail">Android Browser - yesterday</span>
                                                        </div>
                                                        <button type="button" class="btn btn-link btn-logout" data-container="body" data-toggle="tooltip" title="Close this login session"><i class="fa fa-times-circle text-danger"></i></button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <div class="body">
                                            <h6>Connected Social Media</h6>
                                            <ul class="list-unstyled list-connected-app">
                                                <li>
                                                    <div class="connected-app">
                                                        <i class="fa fa-facebook app-icon"></i>
                                                        <div class="connection-info">
                                                            <h3 class="app-title">FaceBook</h3>
                                                            <span class="actions"><a href="javascript:void(0);">View Permissions</a> <a href="javascript:void(0);" class="text-danger">Revoke Access</a></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="connected-app">
                                                        <i class="fa fa-twitter app-icon"></i>
                                                        <div class="connection-info">
                                                            <h3 class="app-title">Twitter</h3>
                                                            <span class="actions"><a href="javascript:void(0);">View Permissions</a> <a href="javascript:void(0);" class="text-danger">Revoke Access</a></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="connected-app">
                                                        <i class="fa fa-instagram app-icon"></i>
                                                        <div class="connection-info">
                                                            <h3 class="app-title">Instagram</h3>
                                                            <span class="actions"><a href="javascript:void(0);">View Permissions</a> <a href="javascript:void(0);" class="text-danger">Revoke Access</a></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="connected-app">
                                                        <i class="fa fa-linkedin app-icon"></i>
                                                        <div class="connection-info">
                                                            <h3 class="app-title">Linkedin</h3>
                                                            <span class="actions"><a href="javascript:void(0);">View Permissions</a> <a href="javascript:void(0);" class="text-danger">Revoke Access</a></span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="connected-app">
                                                        <i class="fa fa-vimeo app-icon"></i>
                                                        <div class="connection-info">
                                                            <h3 class="app-title">Vimeo</h3>
                                                            <span class="actions"><a href="javascript:void(0);">View Permissions</a> <a href="javascript:void(0);" class="text-danger">Revoke Access</a></span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Datepicker from 'vuejs-datepicker';
import BreadCrumbCustom from "../../BreadCrumbCustom.vue";
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

export default {
    name:'ProfileComponent',
    components: {
      BreadCrumbCustom,
      Datepicker,
      ValidationObserver,
      ValidationProvider
    },
    data (){
        return{
            activeTab: 'Settings',
            routeProfile: '',
            seeImage: null,
            selectedImage: '',
            userData: {
              admissionDate: null,
              birthdate: null,
              email: "",
              emailCorp: "",
              gender: "",
              identificationNumber: "",
              name: "",
              phoneNumber: "",
              profileImage: "",
              surname: "",
              username: "",
              firstName: "",
              middleName: "",
              lastname: "",
              secondSurname: "",
            },
            changePassword: {
              password: "",
              newPassword: "",
              confirmNewPassword: "",
            },
            asset: {
                item: '',
                description: '',
                status: 'new',
                type: 'gift',
                date: '',
                notes: '',
            },
        }
    },
    computed: {
      ...mapGetters('userStore', ['getAssets']),

      isAdmin() {
        let roles = localStorage.getItem('roles');
        if (roles) {
            roles = JSON.parse(roles);
            return roles.includes('ROLE_ADMIN');
        }
        return false;
      },

      assetsList() {
        return this.getAssets || [];
      },
 
      user(){
        const userInfo =  this.$store.getters['userStore/getUserById'];
        return userInfo ? userInfo : '';
      },
    },
    watch: {
      user() {
        this.userData = { ...this.user.mainData };
        this.separateNameAndSurname();
        this.routeProfile = process.env.VUE_APP_BACK_BASE + 'media/img/profiles/' + this.userData.profileImage;
      },
      $route(to, from){
        if(to.params.id !== from.params.id){
          this.separateNameAndSurname();
        }
      }
    },
    async created(){
      this.getUser();
      this.userData = { ...this.user.mainData };

      // assets \\ equipments del user
      await this.getAllUserAssetsAction({userId: +this.$route.params.id})

      this.separateNameAndSurname();
    },
    methods:{
        ...mapActions('userStore', ['SaveUserAssetAction', 'getAllUserAssetsAction', 'deleteUserAssetAction']),

        async deleteAsset(asset) {
          await this.deleteUserAssetAction({asset})
        },

        isActive: function (currentTab) {
            return this.activeTab === currentTab
        },
        setActive: function (currentTab) {
            this.activeTab = currentTab 
        },
        getUser(){
          const userId = this.$route.params.id;
          this.$store.dispatch('userStore/getAllDataUserByIdAction', {
            payload: userId,
          });
        },
        separateNameAndSurname(){
          const { name, surname } = this.userData;

          if (name && surname ){
            const nameParts = name.split(" ");
            const surnameParts = surname.split(" ");

            this.userData.firstName = nameParts[0];
            this.userData.lastname = surnameParts[0];

            if(nameParts.length > 1){
              this.userData.middleName = nameParts.slice(1).join(" ");
            }
            if(surnameParts.length > 1){
              this.userData.secondSurname = surnameParts.slice(1).join(" ");
            }
          }
        },
        async updateUser(val){
          let data;

          if (val === "asset") {
            try {
              // Validar el formulario antes de continuar
              await this.$refs.form.validate();
              // Si el formulario es válido, procedemos a guardar el activo
              if (!this.$refs.form.errors.length) {
                this.asset.userId = this.userData.id; // Asignar userId al activo
                const data = { ...this.asset };

                console.log("Datos del activo:", data);

                // Llamar a la acción para guardar el activo
                const response = await this.SaveUserAssetAction(data);
                console.log('Respuesta de SaveUserAsset:', response);

                // Limpiar el formulario después de guardar exitosamente
                this.clearForm();
                // Reiniciar los errores de validación
                this.$refs.form.reset();

              } else {
                console.error('El formulario tiene errores de validación');
              }
            } catch (error) {
              console.error('Error al guardar el activo:', error);
            }
          } else if ('settings') {

            data = {
              ...this.userData,
            };

            this.$store.dispatch('userStore/updateMainDataUserAction', {
              payload: data,
              callback: () => {
                this.$store.dispatch('LoginStore/getMainDataUserByIdAction', {
                  userId: data.id,
                });
              }
            });
            this.submitImage();
          }
        },
        clearForm() {
          // Limpiar los campos del formulario
          this.asset.item = '';
          this.asset.description = '';
          this.asset.status = 'new';
          this.asset.type = 'gift';
          this.asset.date = '';
          this.asset.notes = '';
        },
        getErrorMessage(fieldName, errors) {
          if (errors.length) {
            return `The ${fieldName} is required`;
          }
          return '';
        },
        uploadImage(event){
          const file = event.target.files[0];
          this.seeImage = URL.createObjectURL(file);
          this.selectedImage = file;
        },
        submitImage(){
          if(this.selectedImage){
            let fd = new FormData();
            fd.append('profileImage', this.selectedImage);
            fd.append('id', this.userData.id);
            console.log(fd);
            console.log(this.selectedImage);
            console.log(this.userData.profileImage);
            this.$store.dispatch('userStore/postProfileImageAction', {
              params: fd,
              callback: () => {
                this.$store.dispatch('LoginStore/getMainDataUserByIdAction', {
                  userId: this.userData.id,
                });
              }
            });
          }else{
            console.error('No file has been selected.');
          }
        },
        UpdatePassword(){
          let data;
          let dataPassword;

          data = {
            ...this.userData,
          };
          dataPassword = {
            ...this.changePassword,
          };

          this.$store.dispatch('userStore/changePassword', {
            payload: data,
            props: {
              pass: dataPassword.password,
              newPassword: dataPassword.newPassword,
              confirmNewPassword: dataPassword.confirmNewPassword
            },
            callback: () => {
              this.$store.dispatch('LoginStore/getMainDataUserByIdAction', {
                userId: data.id,
              });
            }
          })
        }
    },
}
</script>
<style>
.text-danger {
  color: red;
}
.vdp-datepicker input {
    border: none;
    background: transparent;
    background-color: transparent !important;
    height: 1% !important;
}
.vdp-datepicker__calendar {
    top: 41px;
    left: 0px !important;
}
</style>