import actions from './actions.js';
import mutations from './mutations.js';

const state = {
    userMeta: null,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};