<template>
      <div class="card">
        <div class="header">
            <h2>Next Birthdays</h2>
        </div>
        <div class="body">
            <!-- <hr> -->
            <ul class="right_chat list-unstyled mb-0">
                <li class="birthday-today" v-for="(userDetail,index) in userState" :key="index">
                    <a @click="infoUser(userDetail.id)" href="javascript:void(0)">
                        <div class="media">
                            <img class="media-object" :src="userDetail.profile_image? routeProfile + userDetail.profile_image : require('@/assets/userDefaultImg.jpg')" alt="user">
                            <div class="media-body mt-2">
                                <span class="name">
                                    {{ userDetail.name.split(' ')[0] }} {{ userDetail.surname.split(' ')[0] }}
                                    <small class="float-right">
                                        {{ userDetail.birthdate }}
                                    </small>
                                </span>
                            </div>
                        </div>
                    </a>                            
                </li>                                                       
            </ul>                            
        </div>
    </div>
</template>
<script>
 
export default {
    name:'BirthdayFeed',
    data(){
        return {
            routeProfile: '',
            userData: {
                firstName: "",
                lastname: "",
                birthdate: "",
                profileImage: "",
            },
        }
    },
    computed: {
        userState(){
            const userList = this.$store.getters['userStore/getUserBirthdays'];
            return userList ? userList : '' ;
        },
    },
    watch:{
        userState(){
            this.routeProfile = process.env.VUE_APP_BACK_BASE + 'media/img/profiles/';
        }
    },
    created() {
        this.getUserList();   
    },
    methods: {
        getUserList() {
          this.$store.dispatch('userStore/getBirthdaysUserList');
        },
        infoUser(user){
          this.$router.push(`/app/app-directory/info/${user}`);
        },
    }, 
}
</script>
<style scoped>
</style>