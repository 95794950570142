import axios from 'axios';

// collectionOperations
const postUserMeta = async (params) => axios.post('/userMeta', params);
// itemOperations
const putUserMetaById = async (payload) => axios.put(`/user/${payload.id}/userMeta`, payload);
const deleteUserMetaById = async (payload) => axios.delete(`/user/${payload.id}/userMeta`, payload);

export default{
    postUserMeta,
    putUserMetaById,
    deleteUserMetaById,
};