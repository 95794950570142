import axios from 'axios';

//CollectionsOperations
const getNews = async (categoryId, searchQuery) => {
    const response = await axios.get('/news', {
        params: {
            categoryId,
            searchQuery
        }
    });
    return response.data; 
};

const getLatestNews = async () => await axios.get(`/news/latest`);

const getNewsById = async(newsID) => await axios.get(`/news/${newsID}`);

const getNewsByCategoryId = async(categoryId) => await axios.get(`/news/category/${categoryId}`);

const saveNews = async(payload) => axios.post('/news', payload, { headers: {
    'Content-type': "multipart/form-data"
}});

const updateNews = (payload) => axios.post('/news/edit', payload, {
    headers: {
        'Content-type': "multipart/form-data"
    }
});

const deleteCategory  = async (payload) => axios.post(`/news/delete`, payload)

export default{
    updateNews,
    getNews,
    getLatestNews,
    getNewsById,
    getNewsByCategoryId,
    saveNews,
    deleteCategory
}