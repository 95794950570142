var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vertical-align-wrap"},[_c('div',{staticClass:"vertical-align-middle auth-main"},[_c('div',{staticClass:"auth-box"},[_vm._m(0),_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"body"},[_c('form',{staticClass:"form-auth-small",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._m(2),_vm._m(3),_c('button',{staticClass:"btn btn-primary btn-lg btn-block",attrs:{"type":"submit"}},[_vm._v("REGISTER")]),_c('div',{staticClass:"bottom"},[_c('span',{staticClass:"helper-text"},[_vm._v("Already have an account? "),_c('router-link',{attrs:{"to":"/authentication/page-login"}},[_vm._v("Login")])],1)])]),_vm._m(4),_vm._m(5),_vm._m(6)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("../../../assets/Bitecla-logo2.svg"),"alt":"Bitecla"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('p',{staticClass:"lead"},[_vm._v("Create an account")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label sr-only",attrs:{"for":"signup-email"}},[_vm._v("Email")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","id":"signup-email","placeholder":"Your email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label sr-only",attrs:{"for":"signup-password"}},[_vm._v("Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","id":"signup-password","placeholder":"Password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separator-linethrough"},[_c('span',[_vm._v("OR")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-signin-social"},[_c('i',{staticClass:"fa fa-facebook-official facebook-color"}),_vm._v(" Sign in with Facebook")])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-signin-social"},[_c('i',{staticClass:"fa fa-twitter twitter-color"}),_vm._v(" Sign in with Twitter")])
}]

export { render, staticRenderFns }