<template>
    <div id="main-content" class="blog-page">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12">
                    <div class="card planned_task">
                        <div class="header">
                            <h2>Starter Page</h2>
                            <!-- Sección de Noticias -->
                            <nav>
                                <div class="navbar obj-width">
                                    <a class="logo" href="index.html">NEWS.</a>
                                    <div class="search-container">
                                        <input type="text" placeholder="Search news here..." id="search-input" v-model="searchQuery">
                                        <button id="search-button" @click="searchNews">Search</button>
                                    </div>
                                </div>
                            </nav>
                            <main class="obj-width" id="blog-container">
                                <div class="blog-card" v-for="article in articles" :key="article.url" @click="openArticle(article.url)">
                                    <img :src="article.urlToImage" :alt="article.title">
                                    <h2>{{ truncate(article.title, 30) }}</h2>
                                    <p>{{ truncate(article.description, 120) }}</p>
                                </div>
                            </main>
                            <!-- Fin de Sección de Noticias -->
                            <ul class="header-dropdown">
                                <li class="dropdown">
                                    <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"></a>
                                    <ul class="dropdown-menu dropdown-menu-right">
                                        <li><a href="javascript:void(0);">Action</a></li>
                                        <li><a href="javascript:void(0);">Another Action</a></li>
                                        <li><a href="javascript:void(0);">Something else</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="body">
                            <h4>Starter Page</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'

export default {
    name: 'BlankComponent',
    components: {
        BreadCrumb,
    },
    data() {
        return {
            articles: [],
            searchQuery: ''
        }
    },
    methods: {
        async fetchRandomNews() {
            const apiKey = '3a2c775a726a42ec9b2b45c9b8add1c3';
            const apiUrl = `https://newsapi.org/v2/top-headlines?country=us&pageSize=10&apikey=${apiKey}`;

            try {
                const response = await fetch(apiUrl);
                const data = await response.json();
                this.articles = data.articles || [];
            } catch (error) {
                console.error("Error fetching random news", error);
            }
        },
        async searchNews() {
            const query = this.searchQuery.trim();
            if (query !== "") {
                const apiKey = '3a2c775a726a42ec9b2b45c9b8add1c3';
                const apiUrl = `https://newsapi.org/v2/everything?q=${query}&pageSize=10&apikey=${apiKey}`;

                try {
                    const response = await fetch(apiUrl);
                    const data = await response.json();
                    this.articles = data.articles || [];
                } catch (error) {
                    console.error("Error fetching news by query", error);
                }
            }
        },
        truncate(text, length) {
            if (!text) return ''; // Manejar casos donde text es null o undefined
            return text.length > length ? text.slice(0, length) + "..." : text;
        },
        openArticle(url) {
            window.open(url, "_blank");
        }
    },
    mounted() {
        this.fetchRandomNews();
    }
}
</script>

<style scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    position: relative;
}

.obj-width {
    max-width: 1200px;
    margin: auto;
}

nav {
    background-color: #2d4c50;
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.3);
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.3rem 0;
}

.navbar .logo {
    color: #fff;
    text-decoration: none;
    letter-spacing: 2px;
    font-size: 2.2rem;
}

.navbar .logo:hover {
    color: #138065;
}

#search-input {
    max-width: 400px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    color: #333;
}

#search-input:hover {
    background-color: black;
    color: white;
}

#search-button {
    padding: 10px 20px;
    background-color: #138065;
    color: #fff;
    border: none;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
}

#search-button:hover {
    background-color: rgb(255, 255, 255);
    color: black;
}

#blog-container {
    padding: 3rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.blog-card {
    width: 280px;
    border: 1px solid #3fdb85;
    border-radius: 10px;
    cursor: pointer;
    padding: 1rem;
    margin: 10px;
}

.blog-card:hover {
    box-shadow: 4px 4px 10px rgba(78, 207, 164, 0.1);
}

.blog-card img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.blog-card h2 {
    color: #141414;
    font-size: 1.7rem;
    font-weight: 500;
}

.blog-card p {
    font-size: 1.2rem;
    padding-top: 0.7rem;
}

@media (max-width: 500px) {
    nav {
        padding: 0 20px;
    }
    .navbar .logo {
        font-size: 1.8rem;
    }
    #search-input {
        width: 150px;
        padding: 10px 10px;
    }
    .blog-card {
        width: 100%;
    }
}
</style>