import axios from 'axios';
// import LoginApi from './services/auth/login/_store/index.js';

window.axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE || 'http://localhost:8080/api/';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.request.use(function(config){
        return config;
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalConfig = error.config;
        if (error.response) {
            const { data, status } = error.response;
            switch (status) {
                case 401:
                    if (data && data.message === 'Expired JWT Token') {
                        // LoginApi.refreshToken();
                        return axios(originalConfig);
                    }
                    break;
                default:
                    break;
            }
        }
        return Promise.reject(error);
    },
);
