<template>
    <div class="card right-box">
        <div class="header">
            <h2>Categories</h2>
        </div>
        <div class="body widget">
            <ul class="list-unstyled categories-clouds m-b-0">
                <li v-for="category in categoriesList" :key="category.id" class="mr-2">
                    <button class="btn btn-light btn-category" 
                            :class="{ 'active': activeCategoryId === category.id }"
                            @click="searchNewsByCategoryId(category)">
                        <span class="category-name">{{ category.name }}</span>
                        <span class="category-count" :class="{ 'hover-active': activeCategoryId !== category.id }">{{ category.count }}</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    name: 'CategoriesTags',
    props: {
        categoriesList: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters('newsStore', ['categorySelected', 'activeCategoryId']),
    },
    methods: {
        ...mapActions('newsStore', ['getNewsAction']),
        ...mapMutations('newsStore', ['setCategorySelected', 'clearSearchQuery', 'setActiveCategoryId']),

        async searchNewsByCategoryId(category) {
            if(this.categorySelected && this.categorySelected.id !== category.id) {
                this.clearSearchQuery(); // Mutation para limpiar el searchQuery
            }
            this.setActiveCategoryId(category.id); // Establecer la categoría activa
            this.setCategorySelected(category); // Mutation para establecer la categoría seleccionada
            await this.getNewsAction(); // Acción para buscar noticias
        }
    },
    created () {
    }
};
</script>

<style scoped>
.header {
    padding-bottom: 0px !important;
}

.btn-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #007bff;
    color: #007bff;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    width: 100%;
}

.btn-category.active {
    transform: scale(1.05);
}

.btn-category:hover {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
}

/* Cambiar el color de fondo de .category-count solo cuando no está activo */
.btn-category:not(.active):hover .category-count {
    background-color: #fff;
    color: #007bff;
}

.category-name {
    flex: 1;
}

.category-count {
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
}

.hover-active {
    cursor: pointer; /* Cambiar cursor a pointer cuando no está activo */
}
</style>
