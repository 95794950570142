<template>
    <div id="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="body">
                            <ValidationObserver v-slot="{ invalid }" ref="form">
                                <form @submit.prevent="onSubmit">
                                    <div class="form-group">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <input type="text" v-model="news.title" class="form-control" placeholder="Enter news title" />
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <select v-model="news.category_id"  class="form-control show-tick">
                                                <option value="" disabled>Select a category</option>
                                                <option 
                                                    v-for="category in categories"
                                                    :key="category.id"
                                                    :value="category.id"
                                                >
                                                    {{ category.name }}
                                                </option>
                                            </select>
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group m-t-20 m-b-20">
                                        <input type="file" @change="handleFileUpload" class="form-control-file" id="exampleInputFile" aria-describedby="fileHelp">
                                    </div>
                                    <div class="summernote">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <vue-editor v-model="news.description"></vue-editor>
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <button :disabled="invalid" type="submit" class="btn btn-block btn-primary m-t-20">Post</button>
                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { VueEditor } from "vue2-editor";
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import BreadCrumb from '@/components/BreadCrumb';
import { mapActions } from 'vuex';

// Define the validation rule
extend('required', {
    ...required,
    message: 'This field is required'
});

export default {
    name: 'PostComponent',
    components: {
        VueEditor,
        BreadCrumb,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            news: {
                title: '',
                category_id: '',
                description: "",
                file: null,
                publishedAt: null 
            },
            categories: []
        };
    },
    methods: {
        ...mapActions('newsStore', ['saveNewsAction']),
        ...mapActions('categoryStore', ['getCategoriesAction']),

        handleFileUpload(event) {
            this.news.file = event.target.files[0];
        },
        async onSubmit() {
            await this.$refs.form.validate();
            // Si el formulario es válido, establecemos la fecha actual
            if (!this.$refs.form.errors.length) {
                this.news.publishedAt = new Date().toISOString();
                await this.saveNewsAction(this.news);
                this.$router.push('news-list')
            }
        }
    },
    async created () {
        const response = await this.getCategoriesAction()
        this.categories = [ ...response.data ]
    }
}
</script>

<style scoped>
.text-danger {
    color: red;
}
</style>
