<template>
  <div id="main-content" class="blog-page">
    <div class="container-fluid">
      <div class="block-header">
        <bread-crumb-custom :is-profile-user="false"></bread-crumb-custom>
      </div>
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
<!--            <div class="body">-->
<!--              <ul class="nav nav-tabs">-->
<!--                <li class="nav-item"><a class="nav-link" v-on:click="setActive('Settings')"  :class="{ active:isActive('Settings') }">Settings</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" v-on:click="setActive('billings')"  :class="{ active:isActive('billings') }">Billings</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" v-on:click="setActive('preferences')"  :class="{ active:isActive('preferences') }">Preferences</a></li>-->
<!--              </ul>-->
<!--            </div>-->
            <div class="tab-content">

              <div v-if="userData" class="tab-pane" id="Settings" :class="{ active:isActive('Settings') }">
                <h1>USER REGISTER</h1>
                <div class="body" style="padding-bottom: 10px !important;">
                  <h6>Corporate Email</h6>
                  <div class="row clearfix">
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <input type="email" name="email" class="form-control" v-model="userData.emailCorp" placeholder="exampleemail@example.com" required>
                      </div>
                      <div class="form-group">
                        <h6>First Name</h6>
                        <input type="text" class="form-control" placeholder="Juan" v-model="firstName" required>
                      </div>
                      <div class="form-group">
                        <h6>Last name</h6>
                        <input type="text" class="form-control"  v-model="lastname" placeholder="Perez" required>
                      </div>
                      <div class="form-group">
                        <h6>Phone Number</h6>
                        <input type="text" class="form-control" v-model="userData.phoneNumber" placeholder="+58 5555555" required>
                      </div>
                      <div class="form-group">
                        <h6>Birthday</h6>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="icon-present"></i></span>
                          </div>
                          <datepicker class="form-control" :bootstrap-styling="true" v-model="userData.birthdate" required></datepicker>
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>Sex</h6>
                        <select id="genderSelect" class="form-control" v-model="userData.gender" required>
                          <option value="m">Male</option>
                          <option value="f">Female</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12" style="margin-top: -27px;">
                      <div class="form-group">
                        <h6>User Name</h6>
                        <input type="text" class="form-control" v-model="userData.username" placeholder="Examplejuan22" required>
                      </div>
                      <div class="form-group">
                        <h6>Middle Name</h6>
                        <input type="text" class="form-control" v-model="middleName" placeholder="Arturo" required>
                      </div>
                      <div class="form-group">
                        <h6>Second Surname</h6>
                        <input type="text" class="form-control" v-model="secondSurname" placeholder="Martinez" required>
                      </div>
                      <div class="form-group">
                        <h6>Personal Email</h6>
                        <input type="email" name="email" class="form-control" v-model="userData.email" placeholder="Examplepersonal@example.com" required>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="body" style="padding-top: 0 !important; padding-bottom: 10px !important;" >
                  <label>Identification</label>
                  <div class="row clearfix">
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group">
                        <input type="text" class="form-control" v-model="userData.identificationNumber" placeholder="Identification Number" required>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12" style="margin-top: -30px;">
                      <label>DNI Type</label>
                       <select v-model="userData.identificationType" class="form-control" required>
                        <option>--Select Type--</option>
                        <option value="PASSPORT"> Passport </option>
                        <option value="DNI"> DNI </option>
                        <option value="CEDULA"> Cedula </option>
                        <option value="EMPLOYEE_NUMBER">Employee Number</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="body" style="padding-top: 0 !important;" >
                  <label>Admission Date</label>
                  <div class="row clearfix">
                    <div class="col-lg-6 col-md-12">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-calendar"></i></span>
                        </div>
                        <datepicker class="form-control" :bootstrap-styling="true" v-model="userData.admissionDate" required></datepicker>
                      </div>
                    </div>
                  </div>
                </div>

<!--                <div class="body" style="padding-top: 0 !important;" >-->
<!--                  <div class="row clearfix">-->
<!--                    <div class="col-lg-6 col-md-12">-->
<!--                      <div class="form-group">-->
<!--                        <button type="button" class="btn btn-primary mr-1" @click="addUser">Add New User</button> &nbsp;&nbsp;-->
<!--                        <button class="btn btn-default">Cancel</button>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="body">-->
<!--                  <h6>General Information</h6>-->
<!--                  <div class="row clearfix">-->
<!--                    <div class="col-lg-6 col-md-12">-->
<!--                      <div class="form-group">-->
<!--                        <input type="text" class="form-control" placeholder="Emergency contact">-->
<!--                      </div>-->
<!--                      <div class="form-group">-->
<!--                        <input type="text" class="form-control" placeholder="knowed pathologies">-->
<!--                      </div>-->
<!--                      <button type="button" class="btn btn-primary mr-1">Add New User</button> &nbsp;&nbsp;-->
<!--                      <button class="btn btn-default">Cancel</button>-->
<!--                    </div>-->
<!--                    <div class="col-lg-6 col-md-12">-->
<!--                      <div class="form-group">-->
<!--                        <textarea class="form-control" placeholder="Address"></textarea>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="body">
                  <div class="row clearfix">
                    <div class="col-lg-6 col-md-12">
                      <h6>Password</h6>
                      <div class="form-group">
                        <input type="password" class="form-control" v-model="userData.password" placeholder="Password" required>
                      </div>
                      <div class="form-group">
                        <input type="password" class="form-control" v-model="confirmPassword" placeholder="Confirm Password" required>
                      </div>
                      <div class="form-group">
                        <h6>User Type</h6>
                        <select id="userType" class="form-control" v-model="userData.userType" required>
                          <option value="USER">Usuario</option>
                          <option value="ADMIN">Administrador</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <button type="button" class="btn btn-primary mr-1" @click="addUser">Add New User</button> &nbsp;&nbsp;
                  <button class="btn btn-default">Cancel</button>
                </div>

              </div>
              <success-modal v-if="showModal" :message="modalMessage" :isVisible="showModal" @changeValue="showModal = false"></success-modal>
              
              <div class="tab-pane" id="billings" :class="{ active:isActive('billings') }">

                <div class="body">
                  <h6>Payment Method</h6>
                  <div class="payment-info">
                    <h3 class="payment-name"><i class="fa fa-paypal"></i> PayPal ****2222</h3>
                    <span>Next billing charged $29</span>
                    <br>
                    <em class="text-muted">Autopay on May 12, 2018</em>
                    <a href="javascript:void(0);" class="edit-payment-info">Edit Payment Info</a>
                  </div>
                  <p class="margin-top-30"><a href="javascript:void(0);"><i class="fa fa-plus-circle"></i> Add Payment Info</a></p>
                </div>

                <div class="body">
                  <h6>Billing History</h6>
                  <table class="table billing-history">
                    <thead class="sr-only">
                    <tr>
                      <th>Plan</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <h3 class="billing-title">Basic Plan <span class="invoice-number">#LA35628</span></h3>
                        <span class="text-muted">Charged at April 17, 2018</span>
                      </td>
                      <td class="amount">$29</td>
                      <td class="action"><a href="javascript:void(0);">View</a></td>
                    </tr>
                    <tr>
                      <td>
                        <h3 class="billing-title">Pro Plan <span class="invoice-number">#LA3599</span></h3>
                        <span class="text-muted">Charged at March 18, 2018</span>
                      </td>
                      <td class="amount">$59</td>
                      <td class="action"><a href="javascript:void(0);">View</a></td>
                    </tr>
                    <tr>
                      <td>
                        <h3 class="billing-title">Platinum Plan <span class="invoice-number">#LA1245</span></h3>
                        <span class="text-muted">Charged at Feb 02, 2018</span>
                      </td>
                      <td class="amount">$89</td>
                      <td class="action"><a href="javascript:void(0);">View</a></td>
                    </tr>
                    </tbody>
                  </table>
                  <button @click="addUser" type="button" class="btn btn-primary mr-1">Update</button>
                  <button type="button" class="btn btn-default">Cancel</button>
                </div>

              </div>

              <div class="tab-pane" id="preferences" :class="{ active:isActive('preferences') }">
                <div class="row clearfix">
                  <div class="col-lg-6 col-md-12">
                    <div class="body">
                      <h6>Your Login Sessions</h6>
                      <ul class="list-unstyled list-login-session">
                        <li>
                          <div class="login-session">
                            <i class="fa fa-laptop device-icon"></i>
                            <div class="login-info">
                              <h3 class="login-title">Mac - New York, United States</h3>
                              <span class="login-detail">Chrome - <span class="text-success">Active Now</span></span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="login-session">
                            <i class="fa fa-desktop device-icon"></i>
                            <div class="login-info">
                              <h3 class="login-title">Windows 10 - New York, United States</h3>
                              <span class="login-detail">Firefox - about an hour ago</span>
                            </div>
                            <button type="button" class="btn btn-link btn-logout" data-container="body" data-toggle="tooltip" title="Close this login session"><i class="fa fa-times-circle text-danger"></i></button>
                          </div>
                        </li>
                        <li>
                          <div class="login-session">
                            <i class="fa fa-mobile fa-fw device-icon"></i>
                            <div class="login-info">
                              <h3 class="login-title">Android - New York, United States</h3>
                              <span class="login-detail">Android Browser - yesterday</span>
                            </div>
                            <button type="button" class="btn btn-link btn-logout" data-container="body" data-toggle="tooltip" title="Close this login session"><i class="fa fa-times-circle text-danger"></i></button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="body">
                      <h6>Connected Social Media</h6>
                      <ul class="list-unstyled list-connected-app">
                        <li>
                          <div class="connected-app">
                            <i class="fa fa-facebook app-icon"></i>
                            <div class="connection-info">
                              <h3 class="app-title">FaceBook</h3>
                              <span class="actions"><a href="javascript:void(0);">View Permissions</a> <a href="javascript:void(0);" class="text-danger">Revoke Access</a></span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="connected-app">
                            <i class="fa fa-twitter app-icon"></i>
                            <div class="connection-info">
                              <h3 class="app-title">Twitter</h3>
                              <span class="actions"><a href="javascript:void(0);">View Permissions</a> <a href="javascript:void(0);" class="text-danger">Revoke Access</a></span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="connected-app">
                            <i class="fa fa-instagram app-icon"></i>
                            <div class="connection-info">
                              <h3 class="app-title">Instagram</h3>
                              <span class="actions"><a href="javascript:void(0);">View Permissions</a> <a href="javascript:void(0);" class="text-danger">Revoke Access</a></span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="connected-app">
                            <i class="fa fa-linkedin app-icon"></i>
                            <div class="connection-info">
                              <h3 class="app-title">Linkedin</h3>
                              <span class="actions"><a href="javascript:void(0);">View Permissions</a> <a href="javascript:void(0);" class="text-danger">Revoke Access</a></span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="connected-app">
                            <i class="fa fa-vimeo app-icon"></i>
                            <div class="connection-info">
                              <h3 class="app-title">Vimeo</h3>
                              <span class="actions"><a href="javascript:void(0);">View Permissions</a> <a href="javascript:void(0);" class="text-danger">Revoke Access</a></span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Datepicker from 'vuejs-datepicker';
import BreadCrumbCustom from "../../BreadCrumbCustom.vue";
 
export default {
  name:'ProfileComponent',
  components: {
    BreadCrumbCustom,
    Datepicker,
  },
  data (){
    return{
      activeTab: 'Settings',
      selectedImage: '',
      userData: {
        admissionDate: null,
        birthdate: null,
        email: "",
        emailCorp: "",
        gender: "",
        identificationNumber: "",
        name: "",
        phoneNumber: "",
        profileImage: "",
        surname: "",
        username: "",
        password: "",
        userType: ""
      },
      confirmPassword: "",
      firstName: "",
      middleName: "",
      lastname: "",
      secondSurname: "",
      userIdToDisplay: null,
    }
  },
  methods:{
    isActive: function (currentTab) {
      return this.activeTab === currentTab
    },
    setActive: function (currentTab) {
      this.activeTab = currentTab
    },
    addUser(){
      let data;
      if (this.userData.password !== this.confirmPassword){
        console.log("if 1");
        this.showModal = true; // Activando el modal
        this.modalMessage = 'ERROR: PASSWORD INVALID'; // Estableciendo mensaje del modal
        return;
      }
      this.userData.name = `${this.firstName} ${this.middleName}`;
      this.userData.surname = `${this.lastname} ${this.secondSurname}`;

      data = {
        ...this.userData,
      };
      console.log('info ', data);
      this.$store.dispatch('userStore/postUserAction', {
        payload: data,
        callback: () => {
          this.$swal.fire({
                icon: "success",
                title: "Success",
                text: "El usuario ha sido registrado con exito",
            });
        }
      })
    },
  },
}
</script>


<style>
.vdp-datepicker input {
  border: none;
  background: transparent;
  background-color: transparent !important;
  height: 1% !important;
}
.vdp-datepicker__calendar {
  top: 41px;
  left: 0px !important;
}
</style>