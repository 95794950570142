/* eslint-disable */
import categoryApi from '../_api/index.js';
import Swal from 'sweetalert2';

const getCategoriesAction = async ({ commit }) => {
  try {
    const response = await categoryApi.getCategories();
    commit('setCategories', response.data);
    return response
  } catch (error) {
    console.log('Error fetching user assets:', error);
  }
};

const SaveCategoryAction = async ({ commit }, payload) => {
  try {
      const response = await categoryApi.saveCategory(payload)
      commit('addCategory', response.data)
  } catch (error) {
      console.log('err: ', error)
      throw error
  }
};

const deleteCategoryAction = async ({ commit }, payload) => {
  try {
    const response = await categoryApi.deleteCategory(payload);
    if (response.data.status === 'ok') {
          commit('removeCategory', response.data.deletedCategoryId);
          return response
      }
  } catch (error) {
    console.log('error: ', error?.response)
    Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.detail,
    });
  }
};

export default{
  getCategoriesAction,
  SaveCategoryAction,
  deleteCategoryAction
}