<template>
    <div id="main-content" class="blog-page">
        <div class="container-fluid">
    
          <!-- Breadcrumb -->
          <div class="block-header">
                <bread-crumb-custom :user-name="userData.firstName" :user-lastname="userData.lastname" :is-profile-user="true"></bread-crumb-custom>
            </div>
          <!-- /Breadcrumb -->
    
          <div class="row gutters-sm">
            <div class="col-md-4 mb-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center text-center">
                    <img :src="userData.profileImage? routeProfile : require('@/assets/userDefaultImg.jpg')" alt="user" class="rounded-circle" width="150">
                    <div class="mt-3">
                      <h4>{{ userData.firstName }} {{ userData.lastname }}</h4>
                      <p class="text-secondary mb-1">Full Stack Developer</p>
                      <p class="text-muted font-size-sm">Bay Area, San Francisco, CA</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="card mt-3">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe mr-2 icon-inline"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>Website</h6>
                    <span class="text-secondary">https://bootdey.com</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github mr-2 icon-inline"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>Github</h6>
                    <span class="text-secondary">bootdey</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter mr-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>Twitter</h6>
                    <span class="text-secondary">@bootdey</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram mr-2 icon-inline text-danger"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>Instagram</h6>
                    <span class="text-secondary">bootdey</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook mr-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>Facebook</h6>
                    <span class="text-secondary">bootdey</span>
                  </li>
                </ul>
              </div> -->
            </div>
            <div class="col-md-8">
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-4">
                      <h6 class="mb-0">Full Name</h6>
                    </div>
                    <div class="col-sm-8 text-secondary">
                        {{ userData.firstName }} {{ userData.lastname }}
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-4">
                      <h6 class="mb-0">Corporative Email</h6>
                    </div>
                    <div class="col-sm-8 text-secondary">
                      {{ userData.emailCorp }}
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-4">
                      <h6 class="mb-0">Personal Email</h6>
                    </div>
                    <div class="col-sm-8 text-secondary">
                      {{ userData.email }}
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-4">
                      <h6 class="mb-0">Phone</h6>
                    </div>
                    <div class="col-sm-8 text-secondary">
                      {{ userData.phoneNumber }}
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-4">
                      <h6 class="mb-0">Birthday</h6>
                    </div>
                    <div class="col-sm-8 text-secondary">
                      {{ formDate }}
                    </div>
                  </div>
                  <hr>
                  <div v-if="buttonEdit" class="row">
                    <div class="col-sm-12">
                      <button class="btn btn-info " target="__blank" @click="editUser(user.mainData.id)">Edit</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row gutters-sm">
                <!-- <div class="col-sm-6 mb-3">
                  <div class="card h-100">
                    <div class="card-body">
                      <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project Status</h6>
                      <small>Web Design</small>
                      <div class="progress mb-3" style="height: 5px">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <small>Website Markup</small>
                      <div class="progress mb-3" style="height: 5px">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <small>One Page</small>
                      <div class="progress mb-3" style="height: 5px">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <small>Mobile Template</small>
                      <div class="progress mb-3" style="height: 5px">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <small>Backend API</small>
                      <div class="progress mb-3" style="height: 5px">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="col-sm-6 mb-3">
                  <div class="card h-100">
                    <div class="card-body">
                      <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project Status</h6>
                      <small>Web Design</small>
                      <div class="progress mb-3" style="height: 5px">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <small>Website Markup</small>
                      <div class="progress mb-3" style="height: 5px">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <small>One Page</small>
                      <div class="progress mb-3" style="height: 5px">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <small>Mobile Template</small>
                      <div class="progress mb-3" style="height: 5px">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <small>Backend API</small>
                      <div class="progress mb-3" style="height: 5px">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import BreadCrumbCustom from "../../BreadCrumbCustom.vue";

    export default {
        name:'ProfileInfoComponent',
        components: {
            BreadCrumbCustom,
        },
        data(){
            return {
                buttonEdit: false,
                routeProfile: '',
                userData: {
                    admissionDate: null,
                    birthdate: null,
                    email: "",
                    emailCorp: "",
                    gender: "",
                    identificationNumber: "",
                    name: "",
                    phoneNumber: "",
                    profileImage: "",
                    surname: "",
                    username: "",
                    firstName: "",
                    middleName: "",
                    lastname: "",
                    secondSurname: "",
                },
            }
        }, 
        computed: {
            user(){
                const userInfo =  this.$store.getters['userStore/getUserById'];
                return userInfo ? userInfo : '';
            },
            auth(){
                const auth = this.$store.getters['LoginStore/user'];
                return auth ? auth : '';
            },
            formDate() {
                const birthDate = new Date(this.userData.birthdate).toLocaleDateString("en-GB", {
                    timeZone: "UTC",
                });
                return birthDate;
            }
        },
        watch: {
            user() {
                this.userData = { ...this.user.mainData };
                this.separateNameAndSurname();
                this.routeProfile = process.env.VUE_APP_BACK_BASE + 'media/img/profiles/' + this.userData.profileImage;

                this.auth.id === this.user.mainData.id
                    ? this.buttonEdit = true
                    : this.buttonEdit = false;
            },
            $route(to, from){
                if(to.params.id !== from.params.id){
                this.separateNameAndSurname();
                }
            }
        },
        created(){
            this.getUser();
            this.userData = { ...this.user.mainData };
            this.separateNameAndSurname();

        }, 
        methods: {
            getUser(){
                const userId = this.$route.params.id;
                this.$store.dispatch('userStore/getAllDataUserByIdAction', {
                    payload: userId,
                });
            },
            separateNameAndSurname(){
                const { name, surname } = this.userData;

                if (name && surname ){
                    const nameParts = name.split(" ");
                    const surnameParts = surname.split(" ");

                    this.userData.firstName = nameParts[0];
                    this.userData.lastname = surnameParts[0];

                    if(nameParts.length > 1){
                    this.userData.middleName = nameParts.slice(1).join(" ");
                    }
                    if(surnameParts.length > 1){
                    this.userData.secondSurname = surnameParts.slice(1).join(" ");
                    }
                }
            },
            editUser(user){
                this.$router.push(`/app/app-directory/${user}`);
            },  
        }
    }
</script>