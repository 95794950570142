const getUserList = (state) => state.userList;
// const getUserById = (state) => (userId) => {
//     return state.user && state.user.id === userId ? state.user : null;
// };
const getUserById = (state) => state.user;
const getUserBirthdays = (state) => state.usersBirthday;


const getAssets = (state) => {
    return state.assets;
};

export default {
    getUserList,
    getUserById,
    getUserBirthdays,
    getAssets
};

