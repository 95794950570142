<template>
   <div class="vertical-align-wrap">
        <div class="vertical-align-middle auth-main">
            <div class="auth-box">
                <div class="top">
                    <img src="../../../assets/Bitecla-logo2.svg" alt="Bitecla">
                </div>
                <div class="card">
                    <div class="header">
                        <h3>
                            <span class="clearfix title">
                                <span class="number left">Error</span> <span class="text">403 <br/>Forbiddon Error!</span>
                            </span>
                        </h3>
                    </div>
                    <div class="body">
                        <p>You don't have permission to access / on this server.</p>
                        <div class="margin-top-30">
                            <a @click="$router.go(-1)" class="btn btn-default mr-1"><i class="fa fa-arrow-left"></i> <span>Go Back</span></a>
                            <router-link to="/dashboard/index" class="btn btn-primary mr-1"> <i class="fa fa-home"></i> <span>Home</span></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'FourZeroThreeComponent',
    methods: {
        submit(e){
           this.$router.push({ path: '/dashboard/index' })
             e.preventDefault()
            //...
        }
    }
}
</script>
<style scoped>

</style>