const authenticated = (state) => state.token && state.user;

const user = (state) => state.user;

const isAuthenticated = (state) => state.auth;

const isSessionExpired = (state) => state.sessionExpired;

const firstSession = (state) => state.firstSession;

export default {
    authenticated,
    user,
    isAuthenticated,
    isSessionExpired,
    firstSession,
};