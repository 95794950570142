const newsList = (state) => state.news;

const latestNews = (state) => state.latestNews;

const searchQuery = (state) => state.searchQuery;

const categorySelected = (state) => state.categorySelected;

const activeCategoryId = (state) => state.activeCategoryId;

export default {
    newsList,
    latestNews,
    searchQuery,
    categorySelected,
    activeCategoryId
};

