/* eslint-disable */
import UserApi from '../_api/index.js';
import Swal from 'sweetalert2';

const postUserAction = async (_, { payload, callback }) => {
    const postUser = UserApi.postUser(payload);
    postUser
        .then((res) => {
            if(callback) callback(res);
        })
        .catch((error) => {
            const { data } =  error.response;
            //this.$swal(data.title);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: data.detail,
            });
        });
};

const getAllDataUserAction = async ({ commit }) => {
    const getAllDataUser = UserApi.getAllDataUser();
    getAllDataUser
        .then((res) => {
            const { data } = res;
            commit('setUserState', {
            factoring: data,
            });
            console.log(data);
        })
        .catch((error) => {
            const { data } = error.response;
            console.log('User broker list error ', data);
        });
};

//profile avatar
const postProfileImageAction = (_, {params, callback}) => {
    console.log('parametros: ', params);
    const postProfileImage = UserApi.postProfileImage(params);

    postProfileImage.then(() => {
        if (callback) callback();
    }).catch((error) => {
      const {data} = error.response;
      console.log('POST USER IMAGE ERROR: ', data);
    });
};

//user list
const getMainDataUserAction = async ({ commit }) => {
    const getMainData = UserApi.getMainDataUser();
    getMainData
        .then((res) => {
            const { data } = res;
            commit('setUserState', data);
        })
        .catch((error) => {
            const { data } = error.response;
            console.log('User broker list error ', data);
        });
};

//user list by birthday
const getBirthdaysUserList = async ({ commit }) => {
    const getMainData = UserApi.getBirthdayDataUser();
    getMainData
        .then((res) => {
            const { data } = res;
            commit('setUserBirthday', data);
        })
        .catch((error) => {
            const { data } = error.response;
            console.log('User broker list error ', data);
        });
};

const getAllDataUserByIdAction = async ({ commit }, { payload }  ) => {
    const getAllDataById = UserApi.getAllDataUserById(payload);
    getAllDataById
    .then((res) => {
        console.log('res: ', res)
        const { data } = res;
        commit('setUserData', data);
    })
    .catch((error) => {
        const { data } = error;
        console.log('Get user data error ', data);
    });
};

const getMainDataUserByIdAction = async ({ commit }, { userId, callback }  ) => {
    const getMainDataById = UserApi.getMainDataUserById(userId);
    getMainDataById
        .then((res) => {
            const { data } = res;
            commit('setUserState', {
            factoring: data,
            });
            callback();
        })
        .catch((error) => {
            const { data } = error.response;
            console.log('Factoring broker list error ', data);
            callback();
        });
};

const updateMainDataUserAction = async (_,{ payload, callback }) => {
    const updateMainDataUser = UserApi.updateMainDataUser(payload);
    updateMainDataUser
        .then((res) => {
            if(callback) callback(res);
        })
        .catch((error) => {
            const { data } =  error.response;
            console.log('Error saving: ', data);
        });
};

const SaveUserAssetAction = async ({ commit }, payload) => {
    try {
        const response = await UserApi.SaveUserAsset(payload)
        commit('updateUserAssets', response.data)
        return response
    } catch (error) {
        console.log('err: ', error)
        throw error
    }
};

const getAllUserAssetsAction = async ({ commit }, payload) => {
    try {
      const response = await UserApi.getAllUserAssets(payload.userId);
      commit('setUserAssets', response.data);
    } catch (error) {
      console.log('Error fetching user assets:', error);
    }
};


const deleteUserAssetAction = async ({ commit }, payload) => {
    try {
      const response = await UserApi.deleteUserAsset(payload);
      if (response.data.status === 'ok') {
            commit('removeUserAsset', response.data.deletedAssetId);
            return response
        } else {
            console.error('Error deleting asset: ', response);
        }
    } catch (error) {
      console.log('Error fetching user assets:', error);
    }
};
/* 
const SaveUserEquipmentAction = async (_,{ payload, callback }) => {
    const SaveUserEquipment = UserApi.SaveUserEquipment(payload);
    SaveUserEquipment
        .then((res) => {
            console.log('response: ', res)
            if(callback) callback(res);
        })
        .catch((error) => {
            const { data } =  error.response;
            console.log('Error saving: ', data);
        });
};
*/

const changePassword = async (_, { payload, props, callback }) =>
{
    console.log('payload', payload);
    console.log('props', props);

    const resetPass = UserApi.updatePassword(payload, props);

    resetPass
        .then((res) => {
            if(callback) callback(res);
        })
        .catch((error) => {
            console.log(error);
        });
}

export default {
    postUserAction,
    getAllDataUserAction,
    getMainDataUserAction,
    getAllDataUserByIdAction,
    getMainDataUserByIdAction,
    updateMainDataUserAction,
    changePassword,
    postProfileImageAction,
    getBirthdaysUserList,
    SaveUserAssetAction,
    getAllUserAssetsAction,
    deleteUserAssetAction
}