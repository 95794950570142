const setCategories = (state, payload) => {
    state.categories = [ ...payload ];
};

const addCategory = (state, category) => {
    state.categories.unshift(category);
};

const removeCategory = (state, categoryId) => {
    state.categories = state.categories.filter(category => category.id !== categoryId);
};

export default {
    setCategories,
    addCategory,
    removeCategory
};
