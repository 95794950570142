<template>
    <div class="card">
        <div class="header">
            <h2>Latest News</h2>
        </div>
        <div class="body">
            <div v-for="(newsItem, index) in latestNews" :key="index">
                <div class="timeline-item blue" :date-is="formatDate(newsItem.publishedAt)">
                    <h5>{{ newsItem.title }}</h5>
                    <span class="text-primary">
                        {{newsItem.author.username}}
                    </span>
                    <div class="msg">
                        <div v-html="newsItem.description"/>
                        <!-- Mostrar imágenes si existen -->
                        <div v-if="newsItem.image && newsItem.image.path" class="timeline_img m-b-20">
                            <img class="w-25 mr-1" :src="newsItem.image.path" :alt="newsItem.title">
                        </div>
                        <!-- Si no hay imagen, mostrar imagen de respaldo -->
                        <!--<div v-else class="timeline_img m-b-20">
                            <img class="w-25 mr-1" src="http://localhost:3000/img/blog-page-2.5fa2b962.jpg" alt="Default Image">
                        </div>
                        -->
                        <a href="javascript:void(0);" class="m-r-20"><i class="icon-heart"></i> Like</a>
                        <a role="button" data-toggle="collapse" href="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2"><i class="icon-bubbles"></i> Comment</a>
                        <div class="collapse m-t-10" id="collapseExample2">
                            <div class="well">
                                <form>
                                    <div class="form-group">
                                        <textarea rows="2" class="form-control no-resize" placeholder="Enter here for tweet..."></textarea>
                                    </div>
                                    <button class="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
/*eslint-disable */
import { mapGetters } from 'vuex';

export default {
  name: 'LatestNews',
  computed: {
    ...mapGetters('newsStore', ['latestNews']),
  },
  methods: {
    formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    }
  }
};
</script>

<style scoped>

</style>