import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    userList: null,
    user: null,
    usersBirthday: null,
    assets: [] 
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};