import axios from 'axios';

//CollectionsOperations
const registerDayClose = async(params) => axios.post('/register-day-close', params);
const allUserDayClose = async(userId) => axios.get(`/all-user-day-close/${userId}`);

//itemsOperations
const getDayClose = async(dayId) => axios.get(`/day-close/${dayId}`);
const updateDayClose = async(payload) => axios.put(`/update-day-close/${payload.id}`, payload);
const deleteDayClose = async(dayId) => axios.delete(`/delete-day-close/${dayId}`);

export default{
    registerDayClose,
    allUserDayClose,
    getDayClose,
    updateDayClose,
    deleteDayClose,
}