import { render, staticRenderFns } from "./BirthdayFeed.vue?vue&type=template&id=2a0a3942&scoped=true&"
import script from "./BirthdayFeed.vue?vue&type=script&lang=js&"
export * from "./BirthdayFeed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0a3942",
  null
  
)

export default component.exports