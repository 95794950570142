<template>
    <div id="main-content" class="blog-page">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <div class="col-lg-8 col-md-12 left-box">
                    <div class="card single_post" v-for="(news, index) in newsList" :key="index">
                        <!-- http://localhost:3000/img/blog-page-2.5fa2b962.jpg -->
                        <blog-list
                            :news="news"
                            :image="news.image"
                            :title="news.title"
                            :description="news.description"
                            :btn_contain="'Continue Reading'"
                            :sub_details="[
                                { url: 'javascript:void(0);', sub_title: news.category.name },
                                { url: 'javascript:void(0);', sub_title: formatDate(news.publishedAt) }
                            ]"
                        >
                    </blog-list>
                    </div>     
                    <!--              
                    <ul class="pagination pagination-primary">
                        <li class="page-item"><a class="page-link" href="javascript:void(0);">Previous</a></li>
                        <li class="page-item active"><a class="page-link" href="javascript:void(0);">1</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0);">2</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0);">Next</a></li>
                    </ul>   
                    -->             
                </div>
                <div class="col-lg-4 col-md-12 right-box">
                    <search></search>
                    <categories :categoriesList="categoriesList"/>
                    <!--
                    <popular-post></popular-post>
                    <instagram-post></instagram-post>
                    <email-newsletter></email-newsletter>
                    -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import BreadCrumb from '@/components/BreadCrumb'
import BlogLists from '@/components/core/BlogLists.vue'
import CategoriesTags from '@/components/admin/news/CategoriesTags.vue'
import InstagramPost from '@/components/core/InstagramPost.vue'
import EmailNewsletter from '@/components/core/EmailNewsletter.vue'
import Search from '@/components/core/Search.vue'
import PopularPosts from '@/components/core/PopularPosts.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'NewsListComponent',
    components: {
        BreadCrumb,
        'blog-list': BlogLists,
        'categories': CategoriesTags,
        'instagram-post': InstagramPost,
        'email-newsletter': EmailNewsletter,
        'search': Search,
        'popular-post': PopularPosts,
    },
    data () {
        return {
        } 
    },
    computed: {
        ...mapGetters('newsStore', ['newsList', 'categorySelected']),
        ...mapGetters('categoryStore', ['categoriesList']),
    },
    methods: {
        ...mapActions('newsStore', ['getNewsAction', 'getCategoriesAction']),

        formatDate(dateString) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(dateString).toLocaleDateString('en-US', options);
        }
    },
    async created () {
        this.getCategoriesAction();
        await this.getNewsAction();
       
    }
}
</script>

<style scoped>
/* Add any scoped styles here */
</style>
