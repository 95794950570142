<template>
    <div class="card">
        <div class="header">
            <h2>Comments</h2>
        </div>
        <div class="body">
            <ul class="comment-reply list-unstyled">
                <li class="row clearfix">
                    <div class="icon-box col-md-2 col-4"><img class="img-fluid img-thumbnail" src="./../../assets/sm/avatar2.jpg" alt="Awesome Image"></div>
                    <div class="text-box col-md-10 col-8 p-l-0 p-r0">
                        <h5 class="m-b-0">Gigi Hadid </h5>
                        <p>Why are there so many tutorials on how to decouple WordPress? how fast and easy it is to get it running (and keep it running!) and its massive ecosystem. </p>
                        <ul class="list-inline">
                            <li><a href="javascript:void(0);">Mar 09 2018</a></li>
                            <li><a href="javascript:void(0);">Reply</a></li>
                        </ul>
                    </div>
                </li>
                <li class="row clearfix">
                    <div class="icon-box col-md-2 col-4"><img class="img-fluid img-thumbnail" src="./../../assets/sm/avatar3.jpg" alt="Awesome Image"></div>
                    <div class="text-box col-md-10 col-8 p-l-0 p-r0">
                        <h5 class="m-b-0">Christian Louboutin</h5>
                        <p>Great tutorial but few issues with it? If i try open post i get following errors. Please can you help me?</p>
                        <ul class="list-inline">
                            <li><a href="javascript:void(0);">Mar 12 2018</a></li>
                            <li><a href="javascript:void(0);">Reply</a></li>
                        </ul>
                    </div>
                </li>
                <li class="row clearfix">
                    <div class="icon-box col-md-2 col-4"><img class="img-fluid img-thumbnail" src="./../../assets/sm/avatar4.jpg" alt="Awesome Image"></div>
                    <div class="text-box col-md-10 col-8 p-l-0 p-r0">
                        <h5 class="m-b-0">Kendall Jenner</h5>
                        <p>Very nice and informative article. In all the years I've done small and side-projects as a freelancer, I've ran into a few problems here and there.</p>
                        <ul class="list-inline">
                            <li><a href="javascript:void(0);">Mar 20 2018</a></li>
                            <li><a href="javascript:void(0);">Reply</a></li>
                        </ul>
                    </div>
                </li>
            </ul>                                        
        </div>
    </div>
</template>
<script>
export default {
    name:'BlogComments'
}
</script>
<style scoped>

</style>