<template>
    <div id="main-content" class="blog-page">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <div class="col-lg-8 col-md-12 left-box">
                    <div class="card single_post">
                        <div class="body">
                            <div class="img-post">
                                <img class="d-block img-fluid" :src="imageSource" alt="First slide">
                            </div>
                            <h3>{{ news.title }}</h3>
                            <div v-html="news.description"/>
                        </div>
                    </div>
                    <blog-comments></blog-comments>
                    <reply-form></reply-form>
                </div>
                <div class="col-lg-4 col-md-12 right-box">
                    <search></search>
                    <categories-clouds></categories-clouds>
                    <popular-post></popular-post>
                    <instagram-post></instagram-post>
                    <email-newsletter></email-newsletter>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import BreadCrumb from '@/components/BreadCrumb'
import ReplyForm from '@/components/core/ReplyForm.vue'
import BlogComments from '@/components/core/BlogComments.vue'
import CategoriesClouds from '@/components/core/CategoriesClouds.vue'
import InstagramPost from '@/components/core/InstagramPost.vue'
import EmailNewsletter from '@/components/core/EmailNewsletter.vue'
import Search from '@/components/core/Search.vue'
import PopularPosts from '@/components/core/PopularPosts.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'NewsDetailsComponent',
    components: {
        BreadCrumb,
        'reply-form': ReplyForm,
        'blog-comments': BlogComments,
        'categories-clouds': CategoriesClouds,
        'instagram-post': InstagramPost,
        'email-newsletter': EmailNewsletter,
        'search': Search,
        'popular-post': PopularPosts,
    },
    props: {
        slug: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            news: null
        };
    },
    computed: {
        ...mapGetters('newsStore', ['categorySelected']),
        
        imageSource() {
            if (this.news && this.news.image && this.news.image.path) {
                return this.news.image.path;
            }
            return 'http://localhost:3000/img/blog-page-2.5fa2b962.jpg';
        }
    },
    async created() {
        const response = await this.getNewsByIdAction({id: +this.$route.params.id})
        this.setCategorySelected(response.data?.category); // Mutation para asignar el category selected
        this.news = { ...response.data }
    },
    methods: {
        ...mapActions('newsStore', ['getNewsByIdAction']),
        ...mapMutations('newsStore', ['setCategorySelected']),
    }
};
</script>

<style scoped>
/* Estilos opcionales */
</style>
