
<template>
  <div class="row">
    <div class="col-lg-5 col-md-8 col-sm-12">
      <h2><a href="javascript:void(0);" class="btn btn-xs btn-link btn-toggle-fullwidth"><i v-bind:class="{ 'fa fa-arrow-right': show, 'fa fa-arrow-left': !show }" class="fa fa-arrow-left" v-on:click="layoutFullwidth()"></i></a> {{meta}}</h2>
      <ul class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/dashboard/index"><i class="icon-home"></i></router-link></li>
        <li class="breadcrumb-item"> Users list </li>
        <li class="breadcrumb-item"><router-link to="/app/app-directory"> Directory </router-link></li>
        <li class="breadcrumb-item" v-if="isProfileUser"> {{userName}} {{userLastname}}</li>
        <li class="breadcrumb-item" v-else-if="!isProfileUser"> Add User </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    userName: String,
    userLastname: String,
    isProfileUser: Boolean,
  },
  methods:{
    layoutFullwidth(){
      this.show = !this.show;
      let sitebody = document.body;
      this.show ? sitebody.classList.add("layout-fullwidth") : sitebody.classList.remove("layout-fullwidth");
    },
    visitorsOptionsSeries() {
      return this.data = [3,5,1,6,5,4,8,3]
    },
    visitOptionsSeries() {
      return this.data = [4,6,3,2,5]
    },
  }, data (){
    return {
      show:false,
      visitors: {
        grid: {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        },
        tooltip : {
          trigger: 'item',
          formatter:function(params){
            var param = params;
            return param.marker+" "+param.value;
          }
        },
        xAxis: {
          type: 'category',
          show: false,
          boundaryGap: false,
        },
        yAxis : [
          {
            type : 'value',
            show:false,
          }
        ],
        series: [
          {
            type: 'line',
            symbolSize: 1,
            showSymbol: true,
            data: this.visitorsOptionsSeries(),
            lineStyle: {
              width: 1,
            },
          }
        ],
        color: ["#00c5dc"]
      },
      visit: {
        grid: {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        },
        tooltip : {
          trigger: 'item',
          formatter:function(params){
            var param = params;
            return param.marker+" "+param.value;
          }
        },
        xAxis: {
          type: 'category',
          show: false,
          boundaryGap: false,
        },
        yAxis : [
          {
            type : 'value',
            show:false,
          }
        ],
        series: [
          {
            type: 'line',
            symbolSize: 1,
            showSymbol: true,
            data: this.visitOptionsSeries(),
            lineStyle: {
              width: 1,
            },
          }
        ],
        color: ["#f4516c"]
      },
    }
  },created() {
    this.meta = this.$route.meta.title;
  }
};
</script>
<style scoped>
.echart-line-chart{
  height: 20px;
  width: 112px;
}
</style>