<!-- eslint-disable -->
<template>
    <div id="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb :is-new-view="true"></bread-crumb> 
            </div>
            <div class="row clearfix">
                <!-- Itera sobre la lista de noticias -->
                <div class="col-md-4 col-sm-12 mb-4" v-for="(newsItem, index) in newsList" :key="index">
                    <div class="card">
                        <!-- Aquí puedes ajustar el atributo src de la imagen de acuerdo a tu modelo de datos -->
                        <img class="card-img-top" :src="`https://img.freepik.com/vector-gratis/pequenas-personas-que-prueban-garantia-calidad-software-aislado-ilustracion-vectorial-plana-personaje-dibujos-animados-arreglando-errores-dispositivo-hardware-prueba-aplicacion-concepto-servicio-ti_74855-10172.jpg`" alt="Card image cap">
                        <div class="card-body">
                            <h5 class="card-title">{{ newsItem.title }}</h5>
                            <p class="card-text">{{ newsItem.description }}</p>
                            <p class="card-text">
                                <small class="text-muted">
                                    {{ formatDate(newsItem.publishedAt) }} - By {{ newsItem.author.username.toUpperCase() }}
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import BreadCrumb from '@/components/BreadCrumb';
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'BlankComponent',
    components: {
        BreadCrumb
    },
    data() {
        return {
            newsList: [
            {
                id: 1,
                title: "Lorem Ipsum 1",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero et orci ullamcorper, non tincidunt mauris rhoncus. Integer at velit nec orci vulputate placerat. Vivamus condimentum convallis lorem sit amet pharetra.",
                publishedAt: "2024-06-18T10:30:00Z", // Formato ISO 8601 (UTC)
                author: {
                    id: 1,
                    username: "author1"
                }
            },
            {
                id: 2,
                title: "Lorem Ipsum 2",
                description: "Pellentesque sit amet elit eget purus dictum lacinia. Mauris congue, magna sed sollicitudin commodo, diam justo consequat velit, eu feugiat dolor nulla at libero. Nulla ac dignissim felis.",
                publishedAt: "2024-06-17T15:45:00Z",
                author: {
                    id: 2,
                    username: "author2"
                }
            },
            {
                id: 3,
                title: "Lorem Ipsum 3",
                description: "Fusce ultrices erat non turpis facilisis, eget venenatis magna elementum. Nulla facilisi. Aenean eu erat in magna aliquet lobortis sit amet sed risus.",
                publishedAt: "2024-06-16T09:00:00Z",
                author: {
                    id: 3,
                    username: "author3"
                }
            }
        ]
        }
    },
    methods: {
        ...mapActions('newsStore', ['getNewsAction']),
       
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Date(dateString).toLocaleDateString('en-US', options);
        },
    },
    mounted() {
        this.getNewsAction();
    }
}
</script>

<style scoped>
</style>